import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MonthPicker } from '@kidsmanager/ui-core';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  IMemberAssigments,
  IReleasedPlan,
  IRosterRulesConfig,
  IShiftSpecUpdate,
  ITeamConfig
} from '@kidsmanager/util-models';
import { RosterShiftManager } from './roster-shift-manager';
import { RosterEditor } from './roster-editor';
import { SelectableShiftSpec, TeamMember } from './models';
import { RosterVersioner, VersionState } from './roster-versioner';
import {
  PlanCompiler,
  MonthSet,
  compileShifts,
  compileTeam,
  compileStatus,
  standardShifts
} from './helpers';
import { TransposeToggle } from './components';

export const Roster = () => {
  const [daysAsRows, setDaysAsRows] = useState(
    localStorage.getItem('roster-days-as-rows') !== 'false'
  );
  const [config, setConfig] = useState<ITeamConfig>();
  const [rules, setRules] = useState<IRosterRulesConfig>();
  const [specs, setSpecs] = useState<SelectableShiftSpec[]>([]);
  const [team, setTeam] = useState<TeamMember[]>([]);
  const [versionState, setVersionState] = useState<VersionState>('empty');
  const [releases, setReleases] = useState<IReleasedPlan[]>([]);
  const [publicHolidays, setPublicHolidays] = useState<Date[]>([]);

  const [compiler] = useState<PlanCompiler>(new PlanCompiler());
  const [plan, setPlan] = useState<IMemberAssigments[]>([]);

  const client = useContext(ClientBackendContext);
  const params = useParams<{ group: string; month: string }>();
  const navigate = useNavigate();

  const months = useMemo(() => new MonthSet(params.month), [params]);

  useEffect(() => {
    if (!client || !params.group) return;
    const groupId = params.group;
    const { year, month } = months.current;

    Promise.all([
      client.roster.plan.rules(),
      client.roster.team.config(groupId),
      client.roster.team.members(groupId, year, month),
      client.roster.shifts.list(groupId, year, month),
      client.holiday.publicHolidays(year)
    ]).then(([rules, config, members, specs, publicHols]) => {
      const memberIds = members.map((m) => m.id);
      setRules(rules);
      setConfig(config);
      setSpecs(compileShifts(specs, standardShifts()));
      setPublicHolidays(publicHols);
      Promise.all([
        client.roster.team.stats(groupId, year, month, memberIds),
        client.holiday.bookingsForUsers(year, month, memberIds),
        client.roster.plan.find(year, month, memberIds),
        client.roster.plan.find(months.prev.year, months.prev.month, memberIds),
        client.roster.plan.find(months.next.year, months.next.month, memberIds),
        client.roster.plan.releases(groupId, year, month)
      ]).then(([stats, holidays, current, prev, next, releases]) => {
        const plan = compiler
          .establishContext(prev, next, holidays, publicHols)
          .run(current, year, month);
        setTeam(compileTeam(members, stats, compiler.memberHolidays()));
        setVersionState(compileStatus(plan, releases));
        setPlan(plan);
        setReleases(releases);
      });
    });
  }, [client, params.group, months, compiler]);

  const handleTranspose = () => {
    setDaysAsRows((prev) => {
      localStorage.setItem('roster-days-as-rows', (!prev).toString());
      return !prev;
    });
  };

  const handleMonthChange = (year: number, month: number) => {
    navigate(
      `/team/${params.group}/roster/${year}-${month.toString().padStart(2, '0')}`
    );
  };

  const handleSelected = (spec: SelectableShiftSpec) => {
    console.log('selected', spec);
    setSpecs((prev) =>
      prev.map((s) => {
        s.selected = s.id === spec.id ? true : false;
        return s;
      })
    );
  };

  const handleVisibility = (spec: SelectableShiftSpec, visible: boolean) => {
    spec.hide = !visible;
    setSpecs((prev) => [...prev]);
  };

  const handleSpecsChange = (value: IShiftSpecUpdate) => {
    const update = compiler.update(plan, value);
    setSpecs(compileShifts(value.specs, standardShifts()));
    setPlan(update.plan);
    setVersionState('unsaved');
  };

  const handleCancel = async () => {
    if (!params.group) {
      setVersionState('empty');
    } else {
      const memberIds = team.map((m) => m.id);
      const { year, month } = months.current;
      const current = await client.roster.plan.find(year, month, memberIds);
      const plan = compiler.run(current, year, month);
      setPlan(plan);
      setVersionState(compileStatus(plan, releases));
    }
  };

  const handleSave = async () => {
    const { year, month } = months.current;
    await client.roster.plan.update(year, month, PlanCompiler.slice(plan));
    setVersionState('saved');
  };

  const handleOpen = (version: string) => {
    console.log('open', version);
  };

  const handleRelease = async () => {
    if (!params.group) return;
    const ids = team.map((m) => m.id);
    const { year, month } = months.current;
    const release = await client.roster.plan.release(
      params.group,
      year,
      month,
      ids
    );
    setReleases((prev) => [release, ...prev]);
    setVersionState('released');
  };

  const handleUnpublish = async (version: string) => {
    if (!params.group) return;

    const ids = team.map((m) => m.id);
    const { year, month } = months.current;
    const unpublished = await client.roster.plan.unpublish(
      params.group,
      year,
      month,
      version,
      ids
    );
    setReleases((prev) =>
      prev.filter((r) => r.version !== unpublished.version)
    );
    setVersionState('saved');
  };

  const handleDiscard = async (version: string) => {
    if (!params.group) return;

    const ids = team.map((m) => m.id);
    const { year, month } = months.current;
    const deleted = await client.roster.plan.discard(
      params.group,
      year,
      month,
      version,
      ids
    );
    if (version === 'draft') {
      handleCancel();
    } else {
      setReleases((prev) => prev.filter((r) => r.version !== deleted.version));
      setVersionState('saved');
    }
  };
  return months.current.year === 0 || !params.group ? undefined : (
    <div className="flex gap-4 p-4">
      <TransposeToggle onClick={handleTranspose.bind(this)} />
      <div className="min-w-52 md:min-w-64">
        <div className="sticky top-5 z-10 pt-5">
          <MonthPicker
            year={months.current.year}
            month={months.current.month}
            onChange={handleMonthChange.bind(this)}
          />
          <RosterShiftManager
            group={params.group}
            year={months.current.year}
            month={months.current.month}
            specs={specs}
            onSelected={handleSelected.bind(this)}
            onToggled={handleVisibility.bind(this)}
            onChange={handleSpecsChange.bind(this)}
          />
          <RosterVersioner
            year={months.current.year}
            month={months.current.month}
            state={versionState}
            releases={releases}
            onRelease={handleRelease.bind(this)}
            onUnpublish={handleUnpublish.bind(this)}
            onDiscard={handleDiscard.bind(this)}
            onOpen={handleOpen.bind(this)}
            onSave={handleSave.bind(this)}
            onCancel={handleCancel.bind(this)}
          />
        </div>
      </div>
      <RosterEditor
        year={months.current.year}
        month={months.current.month}
        publicHolidays={publicHolidays}
        config={config}
        rulesConfig={rules}
        specs={specs}
        members={team}
        plan={plan}
        daysAsRows={daysAsRows}
        onChange={() => setVersionState('unsaved')}
      />
    </div>
  );
};
