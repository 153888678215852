import { InternalTest } from './internal-test';
import { InternalAssignment } from './internal-assignment';

/**
 * Represents a plan as a bit array, each day in represent by 3 bytes
 * 96 bits, 1 bit per 15 minute slot
 */
export class OverlapMask {
  readonly bits = 32;

  plan: number[] = [];
  test: number[] = [];

  load(plan: InternalAssignment[][], test: InternalTest): void {
    this.plan = this.loadPlan(plan);
    this.test = this.loadTest(test, this.plan.length);
  }

  loadPlan(plan: InternalAssignment[][]): number[] {
    //convert plan into bit array for the test period
    //each bit represents a 15 minute slot
    //each day is 24 hours * 4 slots = 96 bits => 3 bytes
    const length = plan.length * 3;
    const bitArray = new Array<number>(length).fill(0);
    for (let i = 0; i < plan.length; i++) {
      for (let j = 0; j < plan[i].length; j++) {
        const assignment = plan[i][j];
        const start = assignment.value.hours * 4 + assignment.value.mins / 15;
        const end = start + assignment.value.duration / 15;
        for (let k = start; k < end; k++) {
          const index = i * 24 * 4 + k;
          const bitIndex = (index / this.bits) | 0;
          const bitShift = index % this.bits;
          bitArray[bitIndex] |= 1 << bitShift;
        }
      }
    }
    return bitArray;
  }

  loadTest(test: InternalTest, length: number): number[] {
    const bitArray = new Array<number>(length).fill(0);
    const start = test.hours * 4 + test.mins / 15;
    const end = start + test.duration * 4;
    const startIndex = (start / this.bits) | 0;
    const startShift = start % this.bits;
    const endIndex = (end / this.bits) | 0;
    const endShift = end % this.bits;
    if (startIndex === endIndex) {
      //single block case
      bitArray[startIndex] = (1 << (endShift - startShift)) - 1;
    } else {
      //multi block case, set first and last, then loop through middle blocks
      bitArray[startIndex] = 0xffffffff >>> startShift;
      bitArray[endIndex] = 0xffffffff << (this.bits - endShift);
      for (let i = startIndex + 1; i < endIndex; i++) {
        bitArray[i] = 0xffffffff;
      }
    }
    return bitArray;
  }

  overlaps(): boolean {
    for (let i = 0; i < this.plan.length; i++) {
      if (this.plan[i] & this.test[i]) {
        return true;
      }
    }
    return false;
  }

  shiftTestBy(shiftBy: { days: number }): void {
    const shift = 3 * shiftBy.days;
    this.test.unshift(...Array(shift).fill(0));
    this.test.splice(this.test.length - shift, shift);
  }
}
