import { useTranslation } from 'react-i18next';
import { DialogConfirm } from './dialog-confirm';

export interface DialogConfirmUnsavedProps {
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const DialogConfirmUnsaved = (props: DialogConfirmUnsavedProps) => {
  const { t } = useTranslation('admin');
  return (
    <DialogConfirm
      callToAction={t('common.continue')}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
    >
      <div>
        <p>{t('common.unsaved_changes')} </p>
        <p>{t('common.unsaved_continue_confirm')}</p>
      </div>
    </DialogConfirm>
  );
};
