import { useEffect, useMemo, useState } from 'react';
import { Badge } from '@kidsmanager/ui-core';
import { IAssignment, IShiftSpec } from '@kidsmanager/util-models';
import { DayData, SelectableShiftSpec, UserData } from '../models';
import { RosterBlockPopup } from './roster-block-popup';

export interface RosterDayProps {
  specs: SelectableShiftSpec[];
  value?: IAssignment;
  status?: { blocked: boolean; notes: string[] };
  user: UserData;
  day: DayData;
  index?: number;
  asColumn?: boolean;
  onChange?: (day: number, user: string) => void;
}

export const RosterDay = (props: RosterDayProps) => {
  const [shifts, setShifts] = useState<IShiftSpec[]>([]);
  const [blockPopup, setBlockPopup] = useState(false);
  const selected = useMemo(
    () => props.specs.find((spec) => spec.selected),
    [props.specs]
  );

  useEffect(() => {
    if (!props.value || !props.value.shifts.length) {
      setShifts([]);
    } else {
      const filtered = props.specs.filter((spec) =>
        props.value?.shifts.includes(spec.id)
      );
      setShifts(filtered.map((s) => ({ ...s })));
    }
  }, [props.value, props.specs]);

  const status = useMemo(() => {
    return !props.status ||
      shifts.find((s) => s.id === '-holiday-' || s.id === '-unavailable-')
      ? { blocked: false, notes: [] }
      : props.status;
  }, [props.status, shifts]);

  const removeShift = (shift: IShiftSpec) => {
    setShifts((prev) => {
      const existing = prev.findIndex((s) => s.name === shift.name);
      if (props.value && existing >= 0) {
        prev.splice(existing, 1);
        const next = [...prev];
        props.value.shifts = next.map((s) => s.id);
        props.onChange?.(props.day.index, props.user.id);
        return next;
      }
      return prev;
    });
  };

  const addShift = (shift: IShiftSpec) => {
    setShifts((prev) => {
      if (!props.value) {
        return prev;
      }
      const newShift = { ...shift };
      const next = [...prev, newShift];
      props.value.shifts = next.map((s) => s.id);
      props.onChange?.(props.day.index, props.user.id);
      return next;
    });
  };

  const isHoliday = shifts.some((s) => s.id === '-holiday-');
  const canAdd =
    !isHoliday &&
    shifts.length === 1 &&
    selected &&
    selected.name !== shifts[0]?.name;

  const handleToggle = (e: React.MouseEvent, shift: IShiftSpec) => {
    e.stopPropagation();
    if (isHoliday) {
      return;
    }
    if (!selected || selected.symbol === shift.symbol) {
      removeShift(shift);
    } else {
      removeShift(shift);
      addShift(selected);
    }
  };

  const handleClicked = () => {
    if (!selected) {
      console.warn('cannot add shift without selection');
      return;
    }
    if (shifts.length === 0 || canAdd) {
      addShift(selected);
    }
  };

  const handleIgnoreBlock = () => {
    if (shifts.length === 0 && selected) {
      addShift(selected);
      setBlockPopup(false);
    }
  };

  const handleBlockClick = () => {
    setBlockPopup((prev) => !prev);
  };

  return !status.blocked ? (
    <div
      className="group relative flex h-8 w-12 items-center justify-center"
      onClick={handleClicked.bind(this)}
    >
      {shifts.map((shift, index) => (
        <Badge
          key={index}
          name={shift.name}
          color={shift.color}
          count={shift.index}
          symbol={shift.symbol}
          size={shifts.length > 1 ? 'sm' : 'md'}
          onClick={(e) => handleToggle(e, shift)}
        />
      ))}
      {canAdd && (
        <span
          className="material-icons absolute bottom-0 right-0 cursor-pointer opacity-0 group-hover:opacity-100"
          style={{ fontSize: '12px' }}
        >
          add
        </span>
      )}
    </div>
  ) : (
    <div
      className="animate-fadein group relative flex h-8 w-12 items-center justify-center bg-neutral-200/60"
      onClick={handleBlockClick.bind(this)}
    >
      {shifts.length === 0 && (
        <svg
          className="opacity-0 group-hover:opacity-100"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 24C10.34 24 8.78 23.685 7.32 23.055C5.86 22.425 4.59 21.57 3.51 20.49C2.43 19.41 1.575 18.14 0.945 16.68C0.315 15.22 0 13.66 0 12C0 10.34 0.315 8.78 0.945 7.32C1.575 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.575 7.32 0.945C8.78 0.315 10.34 0 12 0C13.66 0 15.22 0.315 16.68 0.945C18.14 1.575 19.41 2.43 20.49 3.51C21.57 4.59 22.425 5.86 23.055 7.32C23.685 8.78 24 10.34 24 12C24 13.66 23.685 15.22 23.055 16.68C22.425 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.425 16.68 23.055C15.22 23.685 13.66 24 12 24ZM12 21.6C13.08 21.6 14.12 21.425 15.12 21.075C16.12 20.725 17.04 20.22 17.88 19.56L4.44 6.12C3.78 6.96 3.275 7.88 2.925 8.88C2.575 9.88 2.4 10.92 2.4 12C2.4 14.68 3.33 16.95 5.19 18.81C7.05 20.67 9.32 21.6 12 21.6ZM19.56 17.88C20.22 17.04 20.725 16.12 21.075 15.12C21.425 14.12 21.6 13.08 21.6 12C21.6 9.32 20.67 7.05 18.81 5.19C16.95 3.33 14.68 2.4 12 2.4C10.92 2.4 9.88 2.575 8.88 2.925C7.88 3.275 6.96 3.78 6.12 4.44L19.56 17.88Z"
            fill="#ddd"
          />
        </svg>
      )}
      {shifts.map((shift, index) => (
        <Badge
          key={index}
          name={shift.name}
          color={shift.color}
          count={shift.index}
          symbol={shift.symbol}
          size={shifts.length > 1 ? 'sm' : 'md'}
          onClick={(e) => handleToggle(e, shift)}
        />
      ))}
      {blockPopup && (
        <div
          className="absolute top-8 z-40"
          style={{
            right:
              !props.asColumn && (props.index || 0) > 4 ? '-32px' : undefined
          }}
        >
          <RosterBlockPopup
            notes={status.notes}
            onClose={setBlockPopup.bind(this, false)}
            onIgnore={handleIgnoreBlock.bind(this)}
          />
        </div>
      )}
    </div>
  );
};
