import { RuleSpec } from './rule-spec';

export const ruleSpecsWeekly: RuleSpec[] = [
  {
    id: 'public-hols',
    category: 'weekly',
    defaults: [],
    values: [],
    enabled: false
  },
  {
    id: 'sundays',
    category: 'weekly',
    defaults: [],
    values: [],
    enabled: false
  },
  {
    id: 'max-hours-week',
    category: 'weekly',
    defaults: [{ key: 'length', type: 'number', value: '60' }],
    values: [],
    enabled: false
  }
];
