import { IMemberAssigments, ITeamConfig } from '@kidsmanager/util-models';
import { DayData, UserData } from '../models';

export const updateDayStatus = (
  config: ITeamConfig,
  plan: IMemberAssigments[],
  durations: Map<string, number>,
  data: DayData[],
  day = -1
) => {
  const apply = (index: number) => {
    const hrs = plan.reduce((acc, user) => {
      const shifts = user.assigned[index]?.shifts;
      if (!shifts || !shifts.length) return acc;
      return (
        acc +
        shifts
          .map((id) => durations.get(id) || 0)
          .reduce((acc, val) => acc + val, 0)
      );
    }, 0);
    if (hrs >= config.fullCover) {
      data[index].cover = 'full';
    } else if (hrs >= config.minimalCover) {
      data[index].cover = 'minimal';
    } else {
      data[index].cover = undefined;
    }
  };
  if (day < 0) {
    for (let i = 0; i < data.length; i++) {
      apply(i);
    }
  } else {
    apply(day - 1);
  }
};

export const updateUserStatus = (
  plan: IMemberAssigments[],
  users: UserData[],
  durations: Map<string, number>,
  userId: string | null = null
) => {
  const apply = (user?: UserData) => {
    const assigned = plan.find((a) => a.id === user?.id)?.assigned;
    if (!user || !assigned) return;
    user.available = assigned.reduce((acc, day) => {
      const hrs = day.shifts
        .map((id) => durations.get(id) || 0)
        .reduce((acc, val) => acc + val, 0);
      return acc - hrs;
    }, user.goal);
    user.warn =
      user.available < -15 ? 'high' : user.available < 0 ? 'low' : undefined;
  };
  if (userId === null) {
    //apply to all users
    users.forEach(apply);
  } else {
    //apply to specified user
    apply(users.find((u) => u.id === userId));
  }
};
