import { IGroup, IGroupSummary } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminGroups {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async summaries(): Promise<IGroupSummary[]> {
    return cache.get('admin_group_summary', async () => {
      const result = await this.fetch('api/admin/group/summary');
      if (result.ok) {
        const groups = (await result.json()) as IGroupSummary[];
        groups.sort((a, b) => a.name.localeCompare(b.name || ''));
        return groups || [];
      }
      return [];
    });
  }

  async find(): Promise<IGroup[]> {
    return cache.get('admin_group', async () => {
      const result = await this.fetch('api/admin/group');
      if (result.ok) {
        return result.json();
      }
      return [];
    });
  }

  async add(): Promise<IGroup | undefined> {
    cache.clear('admin_group');
    const result = await this.fetch('api/admin/group', {
      method: 'POST'
    });
    if (result.ok) {
      return result.json();
    }
    return undefined;
  }

  async update(group: IGroup): Promise<IGroup> {
    cache.clear('admin_group');
    cache.clear('admin_group_summary');
    const result = await this.fetch(`api/admin/group/${group.id}`, {
      method: 'PUT',
      body: JSON.stringify(group)
    });
    if (result.ok) {
      return result.json();
    }
    return group;
  }

  async delete(id: string): Promise<void> {
    cache.clear('admin_group');
    cache.clear('admin_group_summary');
    await this.fetch(`api/admin/group/${id}`, {
      method: 'DELETE'
    });
  }
}
