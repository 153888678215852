import '@kidsmanager/util-extensions';
import {
  IMemberAssigments,
  IShiftSpecUpdate,
  IUserBookings
} from '@kidsmanager/util-models';
import { KeyHoliday } from './compiler-shift';
import { HolidayChecker } from './holiday-checker';

export const expandBy = 7;

export class PlanCompiler {
  private prev: IMemberAssigments[] | undefined = undefined;
  private next: IMemberAssigments[] | undefined = undefined;
  private holidays: IUserBookings[] = [];
  private publicHols: Date[] = [];
  private holidayStats: { id: string; days: number }[] = [];

  establishContext(
    prev: IMemberAssigments[],
    next: IMemberAssigments[],
    holidays: IUserBookings[],
    publicHols: Date[]
  ): PlanCompiler {
    this.prev = prev;
    this.next = next;
    this.holidays = holidays;
    this.publicHols = publicHols;
    return this;
  }

  memberHolidays() {
    return this.holidayStats;
  }

  run(
    current: IMemberAssigments[],
    year: number,
    month: number
  ): IMemberAssigments[] {
    if (!this.prev || !this.next) {
      throw new Error('Context not established');
    }

    this.holidayStats = [];
    const plan = structuredClone(current);
    for (const member of plan) {
      const days = this.addHolidayFor(member, year, month);
      this.holidayStats.push({ id: member.id, days });
      this.expandFor(member);
    }
    return plan;
  }

  /** Prefixes 7 days of prev and appends 7 days of next onto month
   * @why this is required so be can check week stats at the boundaries of the month
   */
  expandFor(member: IMemberAssigments) {
    const prev = this.prev?.find((m) => m.id === member.id);
    const next = this.next?.find((m) => m.id === member.id);
    if (!prev || !next) return;

    const lastSevenDays = prev.assigned.slice(prev.assigned.length - expandBy);
    const nextSevenDays = next.assigned.slice(0, expandBy);
    member.assigned.unshift(...lastSevenDays);
    member.assigned.push(...nextSevenDays);
  }

  addHolidayFor(
    member: IMemberAssigments,
    year: number,
    month: number
  ): number {
    let daysAdded = 0;
    const match = this.holidays.find((b) => b.id === member.id);

    if (!match || match.bookings.length === 0) return daysAdded;

    const lastDay = new Date(year, month, 0).getDate();
    for (const booking of match.bookings) {
      const { start, end } = HolidayChecker.parse(booking, month, lastDay);
      for (let i = start; i <= end; i++) {
        if (HolidayChecker.isWorkday(year, month, i + 1, this.publicHols)) {
          daysAdded += 1;
        }
        member.assigned[i].shifts.push(KeyHoliday);
      }
    }
    return daysAdded;
  }

  update(
    plan: IMemberAssigments[],
    value: IShiftSpecUpdate
  ): { plan: IMemberAssigments[]; isDirty: boolean } {
    let isDirty = false;
    for (const member of plan) {
      for (const assignment of member.assigned) {
        for (const shift of assignment.shifts) {
          if (shift.length === 0) {
            continue;
          }
          for (const change of value.changes) {
            switch (change.action) {
              case 'deleted':
                isDirty = true;
                assignment.shifts = assignment.shifts.filter(
                  (s) => s !== shift
                );
                break;
              case 'updated':
                isDirty = true;
                assignment.shifts = assignment.shifts.map((s) =>
                  s === change.prior ? change.id : s
                );
                break;
            }
          }
        }
      }
    }
    return { plan, isDirty };
  }

  /**
   * Extracts the original plan from the middle of the expanded plan
   */
  static slice(plan: IMemberAssigments[]): IMemberAssigments[] {
    return plan.map((m) => ({
      ...m,
      assigned: m.assigned.slice(expandBy, m.assigned.length - expandBy * 2)
    }));
  }
}
