import { RuleBase, RuleType } from '../rule-base';
import { BlockStatus } from '../rule-models';
import { InternalTest } from '../core/internal-test';
import { InternalAssignment } from '../core/internal-assignment';
import { Interval } from '../core/interval';

export class MaxWithoutNightshift extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSchedule(
    test: InternalTest,
    plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    if (!this.specs.isEnabled('max-without-nightshift') || test.readonly) {
      return;
    }
    const from = this.specs.getTime('nightshift-required', 'from');
    const to = this.specs.getTimeRelativeTo('nightshift-required', 'to', from);
    const maxHours = this.specs.getNumber('max-without-nightshift', 'max');
    const minRestMinutes = this.specs.getNumber('rest-between', 'hours') * 60;

    for (const [day, entries] of plan.entries()) {
      const a = test.interval(day);
      const noEntries = entries.length === 0;
      const noNightShift = !a.contains(from, to);
      if (noEntries && noNightShift && a.hours() > maxHours) {
        blocked[day].blocked = true;
        blocked[day].notes.push(
          `Dienste über ${maxHours} Stunden ohne 'Bereitschaftsdienst' erfordern die Freigabe der Leitung`
        );
        continue;
      }
      for (const entry of entries) {
        const b = entry.interval();
        const mustCombine = Interval.restMinutes(a, b) < minRestMinutes;
        const joined = a.join(b);
        const noNightShift = !joined.contains(from, to);
        if (mustCombine && noNightShift && joined.hours() > maxHours) {
          blocked[day].blocked = true;
          blocked[day].notes.push(
            `Kombinierte Dienste über ${maxHours} Stunden ohne 'Bereitschaftsdienst' erfordern die Freigabe der Leitung`
          );
        }
      }
    }
  }
}
