import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

export interface UserCardProperties {
  userId: string;
  name: string;
  firstName: string;
  selected: boolean;
  path: string;
}

export const UserCard = (props: UserCardProperties) => {
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!props.selected) {
      return;
    }

    setTimeout(() => {
      ref.current?.scrollIntoView({ block: 'center' });
    }, 300);
  }, [props.selected]);

  const to = props.selected
    ? `/admin/users`
    : `/admin/users/${props.userId}/${props.path}`;
  return (
    <Link
      ref={ref}
      to={to}
      className={`outline-focus flex h-16 w-48 flex-col items-center justify-center rounded-lg outline-offset-4 ${props.selected ? 'bg-secondary text-white' : 'bg-primary'}`}
    >
      <span className="font-medium">{props.name}</span>
      <span className="text-sm">{props.firstName}</span>
    </Link>
  );
};
