import { InternalAssignment } from './internal-assignment';
import { InternalTest } from './internal-test';
import { FromTo } from './to-from';

const Monday = 1;

const addToMask = (mask: number[][], offset: number, hours: number): void => {
  const weekIndex = Math.floor(offset / 7);
  const dayIndexInWeek = offset % 7;
  if (weekIndex < mask.length) {
    mask[weekIndex][dayIndexInWeek] += hours;
  }
};
export class WeekHoursMask {
  private startOffset = 0;
  private endOffset = 0;

  weeks: number[][] = [];

  constructor(
    public maxHours: number,
    private oncall: FromTo
  ) {}

  load(dates: Date[], plan: InternalAssignment[][]): WeekHoursMask {
    if (dates.length === 0 || plan.length === 0) {
      return this;
    }

    const middleOfMonth = dates[Math.floor(dates.length / 2)];
    const year = middleOfMonth.getFullYear();
    const month = middleOfMonth.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);

    this.weeks = [];
    for (const [i, day] of dates.entries()) {
      const isMonday = day.getDay() === Monday;
      if (!isMonday && this.weeks.length === 0) {
        continue;
      }
      if (isMonday && day > lastDayOfMonth) {
        this.endOffset = i;
        break; //finished with the month of interest
      }
      if (isMonday && this.weeks.length === 0) {
        this.startOffset = this.weeks.length === 0 ? i : this.startOffset;
        this.weeks.push(Array(7).fill(0));
      }

      const j = (i - this.startOffset) % 7;
      const { before, after } = this.oncall.durations(plan[i]);

      this.weeks[this.weeks.length - 1][j] += before;
      if (j === 6 && day < lastDayOfMonth) {
        this.weeks.push(Array(7).fill(0));
        this.weeks[this.weeks.length - 1][0] += after;
      } else if (j < 6) {
        this.weeks[this.weeks.length - 1][j + 1] += after;
      }
    }
    return this;
  }

  toTestMask(dayIndex: number, test: InternalTest): number[][] {
    const testMask: number[][] = Array.from({ length: this.weeks.length }, () =>
      Array(7).fill(0)
    );
    if (dayIndex < this.startOffset || dayIndex >= this.endOffset) {
      //not adding anything outside the month of interest
      return testMask;
    }
    const offset = dayIndex - this.startOffset;
    const start = test.start(dayIndex);
    const end = test.end(dayIndex);
    const { before, after } = this.oncall.durations([
      { day: dayIndex, start, end, readonly: false }
    ]);

    addToMask(testMask, offset, before);
    addToMask(testMask, offset + 1, after);
    return testMask;
  }

  blockOn(dayIndex: number, testMask: number[][]): boolean {
    if (dayIndex < this.startOffset || dayIndex >= this.endOffset) {
      return false;
    }
    const offset = dayIndex - this.startOffset;
    const i = Math.floor(offset / 7);
    const weekOfInterest = this.weeks[i];
    const sumOfWeek = weekOfInterest.reduce((acc, val, j) => {
      return acc + val + testMask[i][j];
    }, 0);
    return sumOfWeek > this.maxHours;
  }
}
