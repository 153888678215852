import { PrefixType } from '@kidsmanager/util-common';

const urlAlphabet =
  'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict';

export const quickid = (
  prefix: PrefixType,
  strength: 'low' | 'medium' | 'high' = 'medium'
) => {
  let t = '';
  const l = strength === 'low' ? 10 : strength === 'medium' ? 16 : 25;
  const r = window.crypto.getRandomValues(new Uint8Array(l));
  for (let n = 0; n < l; n++) t += urlAlphabet[61 & r[n]];
  return `${prefix}${t}`;
};

export const totpSecret = () => {
  const base32chars = 'abcdefghijklmnopqrstuvwxyz234567';
  const secret = window.crypto.getRandomValues(new Uint8Array(20));
  const bits = Array.from(secret).reduce(
    (acc, byte) => acc + byte.toString(2).padStart(8, '0'),
    ''
  );
  const base32Secret = Array.from(bits.match(/.{1,5}/g) || []).reduce(
    (acc, chunk) => acc + base32chars[parseInt(chunk, 2)],
    ''
  );
  return base32Secret;
};
