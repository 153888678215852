export class HolidayChecker {
  static parse(
    booking: { from: string; to: string },
    month: number,
    lastDayOfMonth: number
  ): { start: number; end: number } {
    const from = booking.from.split('-').map((n) => parseInt(n));
    const to = booking.to.split('-').map((n) => parseInt(n));
    const start = from[1] === month ? from[2] - 1 : 0;
    const end = to[1] === month ? to[2] - 1 : lastDayOfMonth - 1;
    return { start, end };
  }
  static isWorkday(
    year: number,
    month: number,
    day: number,
    publicHolidays: Date[]
  ): boolean {
    const test = new Date(year, month - 1, day);
    const isWeekend = test.isWeekend();
    const isPublicHoliday = !!publicHolidays.find((h) => h.dateMatches(test));
    return !isWeekend && !isPublicHoliday;
  }
}
