import '@kidsmanager/util-extensions';

export const isMonthInRange = (
  month: string,
  start: string,
  end: string | undefined
) => {
  if (!month) {
    return true;
  }
  const [year, monthNum] = month.split('-').map(Number);
  const [startYear, startMonth] = start.split('-').map(Number);
  if (year < startYear || monthNum < startMonth) {
    return false;
  }

  if (!end) {
    return true;
  }
  const [endYear, endMonth] = end.split('-').map(Number);
  return year <= endYear && monthNum <= endMonth;
};

export const prependToList = <T extends { from: string; to?: string }[]>(
  id: string,
  values: T,
  date: Date
): T => {
  const toDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const newValues = { id, from: '' };
  if (values.length > 0) {
    if (values[0].to) {
      const lastTo = new Date(values[0].to.split('.').reverse().join('-'));
      newValues.from = toDate(lastTo.addDays(1));
    } else {
      const lastFrom = new Date(values[0].from.split('.').reverse().join('-'));
      if (lastFrom >= date) {
        values[0].to = toDate(lastFrom.addDays(1));
        newValues.from = toDate(lastFrom.addDays(2));
      } else {
        values[0].to = toDate(date.addDays(-1));
        newValues.from = toDate(date);
      }
    }
  } else {
    newValues.from = toDate(date);
  }

  const update = [newValues, ...values];

  return update as T;
};
