import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  IEmailState,
  IGroupSummary,
  IRosterTemplate,
  IUser
} from '@kidsmanager/util-models';
import { useContext, useEffect, useMemo, useState, useTransition } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserCard } from './components/user-card';
import { LinkButton } from '@kidsmanager/ui-core';
import { MasterDetail } from '../components/master-detail';
import { UserSearch } from './components/user-search';
import { UserSearchSummary } from './components/user-search-summary';
import { FilterDefinition, FilterLogic } from './helpers/filter-logic';
import { AdminUserDetail } from './admin-user-detail';
import { useTranslation } from 'react-i18next';

const userSort = (a: IUser, b: IUser) =>
  (a.lastName || '').localeCompare(b.lastName || '');

export const AdminUsers = () => {
  const client = useContext(ClientBackendContext);

  const [modules, setModules] = useState(0);
  const [activeUsers, setActiveUsers] = useState<IUser[]>([]);
  const [lockedUsers, setLockedUsers] = useState<IUser[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filter, setFilter] = useState<FilterDefinition>();
  const [filtered, setFiltered] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [emailState, setEmailState] = useState<IEmailState>();

  const [groups, setGroups] = useState<IGroupSummary[]>([]);
  const [templates, setTemplates] = useState<IRosterTemplate[]>([]);

  const params = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const filterLogic = useMemo(() => new FilterLogic(), []);
  const { t } = useTranslation('admin');

  useEffect(() => {
    client.admin.users.active().then((users) => {
      if (users) {
        users.sort(userSort);
        setActiveUsers(users);
      }
      client.admin.users.locked().then(setLockedUsers);
    });
    client.admin.roster.templates.find().then(setTemplates);
    client.admin.groups.summaries().then(setGroups);
    setModules(client.auth.modules());
  }, [client]);

  useEffect(() => {
    if (filter) {
      setFiltered(
        filterLogic.apply(groups, templates, activeUsers, lockedUsers, filter)
      );
      setTotalUsers(filter.locked ? lockedUsers.length : activeUsers.length);
    } else {
      setFiltered(activeUsers);
      setTotalUsers(activeUsers.length);
    }
  }, [groups, activeUsers, filter, lockedUsers, templates, filterLogic]);

  useEffect(() => {
    if (params.id) {
      if (params.id === 'new') {
        setSelectedUser({ userId: 'new' } as IUser);
      } else {
        const found = activeUsers.find((u) => u.userId === params.id);
        if (found) {
          setSelectedUser(found);
        } else {
          setSelectedUser(lockedUsers.find((u) => u.userId === params.id));
        }
        client.admin.users.email(params.id).then(setEmailState.bind(this));
      }
    } else {
      setSelectedUser(undefined);
    }
  }, [activeUsers, lockedUsers, params, client, selectedUser?.userId]);

  const handleSearchSubmit = () => {
    if (filtered.length > 0) {
      navigate(`/admin/users/${filtered[0].userId}/profile`);
    }
  };

  const handleSearch = (filter?: FilterDefinition) => {
    if (filter) {
      setFilter(filter);
      setFiltered(
        filterLogic.apply(groups, templates, activeUsers, lockedUsers, filter)
      );
      setTotalUsers(filter.locked ? lockedUsers.length : activeUsers.length);
    } else {
      setFilter(undefined);
      setFiltered(activeUsers);
      setTotalUsers(activeUsers.length);
    }
  };

  const handleAddUser = () => {
    const idp = client.auth.idp();
    if (idp) {
      navigate(`/admin/users/new/link/${idp}`);
    } else {
      navigate('/admin/users/new/profile');
    }
  };

  const handleCancelAddUser = () => {
    navigate('/admin/users');
  };

  const handleUserChange = (user: IUser) => {
    if (user.userId === 'new') {
      const idp = client.auth.idp();
      client.admin.users.create(user, idp).then((updatedUser) => {
        if (updatedUser) {
          // TODO extract to function: addActiveUser
          setActiveUsers((prev) => {
            const updated = [...prev, updatedUser];
            updated.sort(userSort);
            return updated;
          });
          client.admin.users
            .email(updatedUser.userId)
            .then(setEmailState.bind(this));
          navigate(`/admin/users/${updatedUser.userId}/profile`);
        }
      });
    } else {
      client.admin.users.update(user).then((updatedUser) => {
        // if state of user has Changed also update locked users
        if (updatedUser.inactive !== selectedUser?.inactive) {
          if (updatedUser.inactive) {
            setLockedUsers((prev) => {
              const updated = [...prev, updatedUser];
              updated.sort(userSort);
              return updated;
            });
            setActiveUsers((prev) =>
              prev.filter((u) => u.userId !== updatedUser.userId)
            );
          } else {
            setActiveUsers((prev) => {
              const updated = [...prev, updatedUser];
              updated.sort(userSort);
              return updated;
            });
            setLockedUsers((prev) =>
              prev.filter((u) => u.userId !== updatedUser.userId)
            );
          }
          navigate('/admin/users');
          return;
        }
        setLockedUsers((prev) =>
          prev?.map((u) => (u.userId === updatedUser.userId ? updatedUser : u))
        );
        setActiveUsers((prev) =>
          prev?.map((u) => (u.userId === updatedUser.userId ? updatedUser : u))
        );
        client.admin.users
          .email(updatedUser.userId)
          .then(setEmailState.bind(this));
      });
    }
  };

  const extractTab = (): string => {
    const tab = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
    if (['permissions', 'shifts', 'actions'].includes(tab)) {
      return tab;
    }
    return 'profile';
  };

  return (
    <MasterDetail
      widths={{ detail: 700 }}
      master={
        <div className="p-4">
          <div className="sticky top-0 mb-2 bg-white/90 pb-2 pt-5 backdrop-blur-sm">
            <UserSearch
              modules={modules}
              groups={groups}
              templates={templates}
              activeUsers={activeUsers}
              onSearch={handleSearch.bind(this)}
              onSubmit={handleSearchSubmit.bind(this)}
            />
          </div>
          <div className="flex justify-between text-sm">
            <UserSearchSummary matched={filtered.length} total={totalUsers} />
            <LinkButton onClick={handleAddUser.bind(this)}>
              + {t('common.add')}
            </LinkButton>
          </div>
          <div className="mt-2 flex flex-wrap gap-2">
            {filtered.map((user, index) => (
              <UserCard
                key={user.userId}
                name={user?.lastName || ''}
                firstName={user?.firstName || ''}
                userId={user.userId || ''}
                selected={user.userId === selectedUser?.userId}
                path={extractTab()}
              />
            ))}
          </div>
        </div>
      }
      detail={
        selectedUser && (
          <AdminUserDetail
            selectedUser={selectedUser}
            modules={modules}
            groups={groups}
            templates={templates}
            activeUsers={activeUsers}
            lockedUsers={lockedUsers}
            emailState={emailState}
            onUserSaved={handleUserChange.bind(this)}
            onClose={handleCancelAddUser.bind(this)}
          ></AdminUserDetail>
        )
      }
    />
  );
};
