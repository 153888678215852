import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Button,
  DialogConfirm,
  DialogConfirmUnsaved,
  DialogContext,
  GhostButton,
  LinkButton,
  Tabs
} from '@kidsmanager/ui-core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { MasterDetail } from '../components/master-detail';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';
import { IGroup } from '@kidsmanager/util-models';
import { useTranslation } from 'react-i18next';

// TODO only show tabs that are configured
export const AdminGroups = () => {
  const { t } = useTranslation('admin');

  const tabs = useMemo(
    () => [
      { path: '', title: t('groups.general.title') },
      { path: 'features', title: t('groups.features.title') },
      { path: 'templates', title: t('groups.templates.title') },
      { path: 'addressbook', title: t('groups.addressbook.title') },
      { path: 'advanced', title: t('groups.advanced.title') }
    ],
    [t]
  );

  const params = useParams<{ id: string }>();
  const client = useContext(ClientBackendContext);
  const dialog = useContext(DialogContext);
  const navigate = useNavigate();
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [selected, setSelected] = useState<IGroup | undefined>();
  const [hasChanges, setHasChanges] = useState({ group: false });
  const [area, setArea] = useState('');

  useEffect(() => {
    client.admin.groups.find().then((newGroups) => {
      newGroups.sort((a, b) => a.name.localeCompare(b.name));
      setGroups(newGroups);
      if (newGroups?.length) {
        const editable =
          newGroups.find((t) => t.id === params.id) || newGroups[0];
        setSelected(structuredClone(editable));
      } else {
        client.admin.groups.add().then((group) => {
          if (!group) {
            return;
          }
          setGroups((prev) => [...prev, group]);
          setSelected(structuredClone(group));
        });
      }
    });
  }, [client, params.id]);

  const handleAddGroup = () => {
    client.admin.groups.add().then((group) => {
      if (!group) {
        return;
      }
      setGroups((prev) => {
        const newGroups = [...prev, group];
        newGroups.sort((a, b) => a.name.localeCompare(b.name));
        return newGroups;
      });
      setSelected(structuredClone(group));
    });
  };

  const handleChange = () => {
    if (!selected) {
      return;
    }
    const clean = groups.find((t) => t.id === selected.id);
    setHasChanges({
      group: JSON.stringify(clean) !== JSON.stringify(selected)
    });
  };

  const handleSave = () => {
    if (!selected) {
      return;
    }
    client.admin.groups.update(selected).then((updated) => {
      //replace the updated template in the list
      const index = groups.findIndex((t) => t.id === updated.id);
      const updatedGroups = [...groups];
      updatedGroups[index] = updated;
      updatedGroups.sort((a, b) => a.name.localeCompare(b.name));
      setGroups(updatedGroups);
      setSelected(structuredClone(updated));
      setHasChanges({ group: false });
    });
  };

  const handleCancel = () => {
    if (!selected) {
      return;
    }
    const clean = groups.find((t) => t.id === selected.id);
    setSelected(structuredClone(clean));
    setHasChanges({ group: false });
    document.dispatchEvent(
      new CustomEvent('adminGroupAction', {
        detail: { reset: true, saved: false }
      })
    );
  };

  const handleDelete = (id: string) => {
    dialog.open(
      <DialogConfirm
        callToAction={t('common.delete')}
        onCancel={() => dialog.close()}
        onConfirm={() => {
          client.admin.groups.delete(id).then(() => {
            // TODO maybe we should store the index before and just reduce it by 1
            const updatedGroups = groups.filter((g) => g.id !== id);
            if (updatedGroups.length > 0) {
              setSelected(structuredClone(updatedGroups[0]));
            } else {
              setSelected(undefined);
            }
            setGroups(updatedGroups);
            dialog.close();
          });
        }}
      >
        <div>{t('groups.delete_confirm', { name: selected?.name })}</div>
      </DialogConfirm>
    );
  };

  const handleGroupSwitch = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: string,
    area: string
  ) => {
    if (hasChanges.group) {
      e.preventDefault();
      dialog.open(
        <DialogConfirmUnsaved
          onCancel={() => {
            dialog.close();
          }}
          onConfirm={() => {
            dialog.close();
            setHasChanges({ group: false });
            navigate(`/admin/groups/${id}/${area}`);
          }}
        ></DialogConfirmUnsaved>
      );
    }
  };

  return (
    <MasterDetail
      widths={{ master: 300 }}
      master={
        <div className="px-4 py-6">
          <h1 className="mb-2">Gruppen</h1>
          <ul className="border-silver-200 min-h-64 border py-2">
            {groups.map((group) => (
              <li
                key={group.id}
                style={{
                  backgroundColor:
                    selected?.id === group.id ? 'var(--color-secondary)' : '',
                  color: selected?.id === group.id ? 'white' : ''
                }}
              >
                <Link
                  className="relative block w-full px-3 leading-10"
                  to={`/admin/groups/${group.id}/${area}`}
                  onClick={(e) => handleGroupSwitch(e, group.id, area)}
                >
                  {group.name}
                  {selected?.id === group.id && (
                    <button
                      onClick={handleDelete.bind(this, selected.id)}
                      className="material-icons absolute right-1 top-1/2 flex h-8 w-8 -translate-y-1/2 items-center rounded-full hover:bg-white/20"
                    >
                      close
                    </button>
                  )}
                </Link>
              </li>
            ))}
          </ul>
          <div className="my-1 text-right">
            <LinkButton onClick={handleAddGroup.bind(this)}>
              + {t('common.add')}
            </LinkButton>
          </div>
        </div>
      }
      detail={
        <>
          <div className="flex justify-end gap-2">
            <GhostButton
              type="reset"
              disabled={!hasChanges.group}
              onClick={handleCancel.bind(this)}
            >
              {t('common.cancel')}
            </GhostButton>
            <Button
              selected={true}
              color="primary"
              disabled={!hasChanges.group}
              onClick={handleSave.bind(this)}
            >
              {t('common.save')}
            </Button>
          </div>
          <Tabs
            basePath={`/admin/groups/${selected?.id}`}
            tabs={tabs}
            onChange={setArea.bind(this)}
          >
            {selected && (
              <Outlet
                context={
                  {
                    selected,
                    onChange: handleChange.bind(this)
                  } as OutletContextAdminGroups
                }
              />
            )}
          </Tabs>
        </>
      }
    />
  );
};
