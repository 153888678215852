import { Input, LinkButton } from '@kidsmanager/ui-core';
import { useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';
import { useTranslation } from 'react-i18next';
import { IGroup } from '@kidsmanager/util-models';

export const AdminGroupGeneral = () => {
  const userForm = useRef<HTMLFormElement>(null);
  const context = useOutletContext<OutletContextAdminGroups>();
  const { t } = useTranslation('admin');
  const i18nPrefix = 'groups.general';

  const { selected } = context;

  const handleLinkGoogle = () => {
    console.log('handle to add google');
  };

  useEffect(() => {
    const handleReset = (event: unknown) => {
      userForm.current?.reset();
    };
    document.addEventListener('adminGroupAction', handleReset);
    return () => window.removeEventListener('adminGroupAction', handleReset);
  }, []);

  const getGroupDataFromForm = (
    formEvent: HTMLFormElement
  ): Partial<IGroup> | undefined => {
    const data = new FormData(formEvent);
    const address = {
      street: data.get('street')?.toString(),
      postcode: data.get('postcode')?.toString(),
      city: data.get('city')?.toString()
    };
    return {
      id: selected.id,
      name: data.get('name')?.toString(),
      general: {
        google: selected.general?.google || false,
        address: address
      }
    };
  };

  const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
    const formGroup = getGroupDataFromForm(e.currentTarget);
    if (formGroup) {
      selected.general = formGroup.general || {};
      selected.name = formGroup.name || selected.name;
      context.onChange?.();
    }
  };

  return (
    <>
      <form
        ref={userForm}
        autoComplete="off"
        key={selected.id}
        className="flex flex-col gap-3"
        onBlur={handleOnChange.bind(this)}
        onChange={handleOnChange.bind(this)}
      >
        <Input
          name="name"
          label={t(`${i18nPrefix}.name`)}
          defaultValue={selected.name}
        />
        <Input
          name="street"
          label={t(`${i18nPrefix}.street`)}
          defaultValue={selected.general.address?.street}
        />
        <Input
          name="postcode"
          label={t(`${i18nPrefix}.postcode`)}
          defaultValue={selected.general.address?.postcode}
        />
        <Input
          name="city"
          label={t(`${i18nPrefix}.city`)}
          defaultValue={selected.general.address?.city}
        />
      </form>

      <div className="mt-8">
        <LinkButton onClick={handleLinkGoogle.bind(this)}>
          <div className="flex gap-2">
            {/* <!-- TODO extract to brand --> */}
            <svg
              width="28"
              height="26"
              viewBox="0 0 28 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.69697 12.7111C5.69697 11.8855 5.84699 11.0939 6.11475 10.3515L1.42804 7.08008C0.514626 8.77528 0 10.6854 0 12.7111C0 14.7351 0.513993 16.644 1.42614 18.3381L6.11032 15.0603C5.84509 14.3214 5.69697 13.5327 5.69697 12.7111Z"
                fill="#FBBC05"
              />
              <path
                d="M13.9259 5.2C15.8882 5.2 17.6605 5.83556 19.0531 6.87556L23.1043 3.17778C20.6356 1.21333 17.4706 0 13.9259 0C8.42259 0 3.69284 2.87676 1.42798 7.08009L6.11469 10.3515C7.19458 7.35511 10.2728 5.2 13.9259 5.2Z"
                fill="#EB4335"
              />
              <path
                d="M13.9259 20.2218C10.2728 20.2218 7.19458 18.0667 6.11469 15.0703L1.42798 18.3411C3.69284 22.545 8.42259 25.4218 13.9259 25.4218C17.3225 25.4218 20.5654 24.3194 22.9992 22.2538L18.5505 19.1101C17.2953 19.8329 15.7147 20.2218 13.9259 20.2218Z"
                fill="#34A853"
              />
              <path
                d="M27.219 12.7115C27.219 11.9604 27.0924 11.1515 26.9025 10.4004H13.926V15.3115H21.3954C21.0219 16.9859 20.0053 18.2732 18.5507 19.111L22.9994 22.2547C25.5561 20.0857 27.219 16.8547 27.219 12.7115Z"
                fill="#4285F4"
              />
            </svg>
            {t(`${i18nPrefix}.google`)}
          </div>
        </LinkButton>
      </div>
    </>
  );
};
