import { RuleBase, RuleType } from '../rule-base';
import { BlockStatus } from '../rule-models';
import { InternalTest } from '../core/internal-test';
import { InternalAssignment } from '../core/internal-assignment';

export class ApprovalSunday extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSchedule(
    _test: InternalTest,
    _plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    if (!this.specs.isEnabled('sundays')) {
      return;
    }
    for (const [i, day] of this.days.entries()) {
      if (day.getDay() === 0) {
        blocked[i].blocked = true;
        blocked[i].notes.push(
          `Arbeiten an einem Feiertag ist nur mit Freigabe der Leitung erlaubt`
        );
      }
    }
  }
}
