import { RuleBase, RuleType } from '../rule-base';
import { BlockStatus } from '../rule-models';
import { InternalTest } from '../core/internal-test';
import { InternalAssignment } from '../core/internal-assignment';
import { Interval } from '../core/interval';

export class MaxHoursTogether extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSchedule(
    test: InternalTest,
    plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    if (!this.specs.isEnabled('max-hours-together') || test.readonly) {
      return;
    }
    const maxHours = this.specs.getNumber('max-hours-together', 'length');
    const minRestMinutes = this.specs.getNumber('rest-between', 'hours') * 60;

    if (test.duration > maxHours) {
      blocked.forEach((b) => {
        b.blocked = true;
        b.notes.push(`Dienst uber ${maxHours} Stunden`);
      });
      return;
    }

    for (const [day, entries] of plan.entries()) {
      for (const entry of entries) {
        const e = entry.interval();
        const testDay = e.endsNextDay() ? day + 1 : day;
        const t = test.interval(testDay);
        const rest = Interval.restMinutes(e, t);
        const hours = Interval.totalHours(e, t);
        if (rest < minRestMinutes && hours > maxHours) {
          blocked[testDay].blocked = true;
          blocked[testDay].notes.push(
            `Kombinierte Dienste von ${hours.toFixed(0)} Stunden, der das maximal erlaubte Limit von ${maxHours} Stunden überschreitet`
          );
        }
      }
    }
  }
}
