import { Assignment, BlockStatus, Test } from './rule-models';
import {
  DayData,
  RulesEngineResult,
  WorkHistory,
  RuleBase,
  RuleType
} from './rule-base';
import * as impl from './impl';
import { InternalTest } from './core/internal-test';
import { InternalAssignment } from './core/internal-assignment';
import { RuleSpecs } from './specs/rule-specs';
import { RuleConfig, RuleSpec } from './specs/rule-spec';

export class WorkRulesEngine {
  configId: string | undefined = undefined;
  firstDay: Date | undefined = undefined;
  history: WorkHistory = { previous: [], current: [], next: [] };
  private specs = new RuleSpecs();
  private prepare: RuleBase[] = [];
  private schedule: RuleBase[] = [];

  constructor() {
    const rules = [
      new impl.RestBetween(),
      new impl.ApprovalAfterX(),
      new impl.ApprovalBeforeX(),
      new impl.MaxHoursTogether(),
      new impl.MaxHoursWeek(),
      new impl.MaxWithoutNightshift(),
      new impl.MinWeeklyRest(),
      new impl.ApprovalPublicHoliday(),
      new impl.ApprovalSunday(),
      new impl.NoOverlaps()
    ];
    this.prepare = rules.filter((r) => r.type() & RuleType.prepare);
    this.schedule = rules.filter((r) => r.type() & RuleType.schedule);
  }

  configure(
    config: RuleConfig[],
    configId = '',
    publicHols: Date[] = [],
    days: Date[] = []
  ): void {
    if (
      this.configId !== undefined &&
      this.configId === configId &&
      this.firstDay === days[0]
    ) {
      return;
    }
    this.firstDay = days[0];
    this.configId = configId;
    this.specs.configure(config);
    for (const rule of this.prepare) {
      rule.setContext(this.specs, publicHols, days);
    }
    for (const rule of this.schedule) {
      rule.setContext(this.specs, publicHols, days);
    }
  }

  rulesByCategory(): Map<string, RuleSpec[]> {
    return this.specs.byCategory();
  }

  setHistory(previous: DayData[], current: DayData[], next: DayData[]): void {
    this.history = { previous, current, next };
  }

  canSchedule(test: Test, assignments: Assignment[][]): BlockStatus[] {
    const internalTest = new InternalTest(test);
    const plan = InternalAssignment.map(assignments);
    const status: BlockStatus[] = plan.map(() => ({
      blocked: false,
      notes: []
    }));

    for (const rule of this.prepare) {
      rule.prepare(plan);
    }
    for (const rule of this.schedule) {
      rule.canSchedule(internalTest, plan, status);
    }
    return status;
  }

  canSave(from: Date, until: Date): RulesEngineResult {
    let warning: RulesEngineResult | undefined = undefined;
    for (const rule of this.schedule) {
      const result = rule.canSave(from, until, this.history);
      if (result.status === 'fail') {
        return result;
      }
      if (!warning && result.status === 'warn') {
        warning = result;
      }
    }
    return warning ? warning : { status: 'ok' };
  }
}
