import { IMemberAssigments, IReleasedPlan } from '@kidsmanager/util-models';
import { VersionState } from '../roster-versioner';

export const compileStatus = (
  plan: IMemberAssigments[],
  releases: IReleasedPlan[]
): VersionState => {
  const lastModification = plan.reduce(
    (max, member) => {
      if (!member.modified) return max;
      const timestamp = new Date(member.modified);
      return !max || timestamp > max ? timestamp : max;
    },
    undefined as Date | undefined
  );
  const lastRelease = new Date(
    releases.length > 0 ? releases[releases.length - 1].releasedAt : 0
  );

  return !lastModification
    ? 'empty'
    : lastModification > lastRelease
      ? 'saved'
      : 'released';
};
