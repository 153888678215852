export const datesOverlap = (
  values: { from: string; to?: string }[]
): boolean => {
  if (values.length <= 1) return false;

  const converted = values
    .map((x) => ({
      from: new Date(x.from.split('.').reverse().join('-')).getTime(),
      to: x.to
        ? new Date(x.to.split('.').reverse().join('-')).getTime()
        : undefined
    }))
    .sort((a, b) => (a.from > b.from ? 1 : -1));

  for (let i = 0; i < values.length - 1; i++) {
    const to = converted[i].to;
    if (to && to >= converted[i + 1].from) {
      return true;
    }
  }
  return false;
};
