import { Test } from '../rule-models';
import { Interval } from './interval';

export class InternalTest {
  constructor(test: Test) {
    this.hours = test.hours;
    this.mins = test.mins;
    this.duration = test.duration;
    this.readonly = test.readonly;

    const start = test.hours * 60 + test.mins;
    this._starts = Array.from(
      { length: 31 + 14 },
      (_, day) => start + day * 24 * 60
    );
    this._ends = this._starts.map((start) => start + test.duration * 60);
  }

  hours: number;
  mins: number;
  duration: number;
  readonly?: boolean;

  private _starts: number[] = [];
  private _ends: number[] = [];

  start(day: number): number {
    return this._starts[day];
  }

  end(day: number): number {
    return this._ends[day];
  }

  interval(day: number): Interval {
    return new Interval(this._starts[day], this._ends[day]);
  }
}
