import { Input, Option, Select } from '@kidsmanager/ui-core';
import { IUser, IUserAllowance } from '@kidsmanager/util-models';
import { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UserAllowanceEditor } from './components/user-allowance-editor';
import { OutletContextAdminUser } from './outlet-context-admin-user';

export const AdminUserShifts = () => {
  const ref = useRef<HTMLFormElement>(null);
  const [startDate, setStartDate] = useState<string>();

  const context = useOutletContext<OutletContextAdminUser>();
  const { selectedUser: user, allowances } = context;

  useEffect(() => {
    const handleReset = (event: unknown) => {
      ref.current?.reset();
    };
    document.addEventListener('adminUserAction', handleReset);
    return () => window.removeEventListener('adminUserAction', handleReset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStartDate(user.originalStartDate);
  }, [user.originalStartDate]);

  const handleOnFormChange = () => {
    if (!ref.current) {
      return;
    }
    const data = new FormData(ref.current);
    setStartDate(data.get('originalStartDate')?.toString());
    const partialUser: Partial<IUser> = {
      employeeId: data.get('employeeId')?.toString(),
      originalStartDate: data.get('originalStartDate')?.toString(),
      dutyRosters: data.get('dutyRosters')?.toString()
    };
    if (partialUser) {
      context.onUserChanged(partialUser, false);
    }
  };

  const handleHoursChange = (values: IUserAllowance[], overlaps: boolean) => {
    context.onAllowancesChanged({ hours: values, hoursInvalid: overlaps });
  };

  const handleHolidayChange = (values: IUserAllowance[], overlaps: boolean) => {
    context.onAllowancesChanged({
      holidays: values,
      hoursInvalid: overlaps
    });
  };

  return (
    <div className="flex max-w-md flex-col gap-4">
      <form
        ref={ref}
        key={`${user.userId}-${context.templates.length}`}
        autoComplete="off"
        className="flex flex-col gap-3"
        onChange={handleOnFormChange.bind(this)}
        onBlur={handleOnFormChange.bind(this)}
      >
        <Input
          name="employeeId"
          label="Dienstnummer"
          defaultValue={user.employeeId}
        />
        <Input
          name="originalStartDate"
          label="erstes Einstellungsdatum"
          placeholder="TT.MM.JJJJ"
          mask="date"
          defaultValue={user.originalStartDate}
        />
        <Select
          name="dutyRosters"
          label="Dienstvorlage"
          defaultValue={user.dutyRosters}
        >
          <Option value="">-- Keine Dienstvorlage --</Option>
          {context.templates?.map((template) => (
            <Option key={template.id} value={template.id}>
              {template.name}
            </Option>
          ))}
        </Select>
      </form>
      {allowances && (
        <>
          <UserAllowanceEditor
            title="Urlaub"
            cols={[{ key: 'value', type: 'number', label: 'Tage' }]}
            values={allowances.holidays}
            startDate={startDate}
            default={25}
            onChange={handleHolidayChange.bind(this)}
          />
          <UserAllowanceEditor
            title="Wochenstunden"
            cols={[
              { key: 'value', type: 'number', label: 'Stunden' },
              { key: 'reference', type: 'string', label: 'Kostenstelle' }
            ]}
            values={allowances.hours}
            disableOverlapCheck={true}
            startDate={startDate}
            default={38}
            onChange={handleHoursChange.bind(this)}
          />
        </>
      )}
    </div>
  );
};
