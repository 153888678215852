import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ShiftRule } from './components/shift-rule';
import {
  RuleCategory,
  RuleSpec,
  WorkRulesEngine
} from '@kidsmanager/util-rules';
import { ContextAdminRosterOutlet } from './context-admin-roster-outlet';
import { IRosterRuleValue } from '@kidsmanager/util-models';
import { useTranslation } from 'react-i18next';

const ruleCategories: RuleCategory[] = ['daily', 'weekly', 'rest', 'other'];

export const AdminRosterRules = () => {
  const context = useOutletContext<ContextAdminRosterOutlet>();
  const { template } = context;
  const [rules, setRules] = useState<Map<string, RuleSpec[]>>(new Map());
  const { t, ready } = useTranslation(['rules', 'admin-roster']);

  useEffect(() => {
    if (!template) {
      return;
    }
    //push setting to the next event loop to allow cancel changes
    setRules(() => new Map());
    setTimeout(() => {
      const engine = new WorkRulesEngine();
      engine.configure(template.rules);
      setRules(engine.rulesByCategory());
    }, 0);
  }, [template]);

  const handleChange = (
    ruleId: string,
    values: IRosterRuleValue[],
    enabled: boolean
  ) => {
    const index = template?.rules.findIndex((x) => x.id === ruleId);
    if (index === -1) {
      template.rules.push({
        id: ruleId,
        values: [...values],
        enabled
      });
    } else {
      template.rules[index].values = [...values];
      template.rules[index].enabled = enabled;
    }
    context.onChange?.();
  };

  return (
    rules &&
    ready && (
      <div className="pl-1">
        {ruleCategories.map((category) => (
          <div key={category}>
            <h2 className="mt-4 text-xs font-bold uppercase text-black/50">
              {t(`roster.category.${category}`, { ns: 'admin-roster' })}
            </h2>
            {rules
              .get(category)
              ?.map((rule) => (
                <ShiftRule
                  key={`${template?.id}-${rule.id}`}
                  description={t(`${rule.category}.${rule.id}`)}
                  values={rule.values}
                  enabled={rule.enabled}
                  onChange={(values, enabled) =>
                    handleChange(rule.id, values, enabled)
                  }
                />
              ))}
          </div>
        ))}
      </div>
    )
  );
};
