import { Assignment } from '../rule-models';
import { Interval } from './interval';

/** a copy of the assignment with pre-calculated offsets */
export class InternalAssignment {
  constructor(
    public value: Assignment,
    public day: number
  ) {
    this.start = InternalAssignment.toTimestamp(value.mins, value.hours, day);
    this.end = this.start + value.duration;
    this.readonly = value.readonly;
    this.partials = [];
  }

  start: number; // start time in minutes from start of month
  end: number; // end time in minutes from start of month
  readonly: boolean;
  partials: InternalAssignment[];

  private _totalMins?: number;

  setEnd(value: number): void {
    this.value.duration = value - this.start;
    this.end = value;
  }

  static toTimestamp(minutes: number, hours: number, days = 0): number {
    return days * 24 * 60 + hours * 60 + minutes;
  }

  /**
   * the span that covers all intervals in the list
   */
  static union(...values: InternalAssignment[]):
    | undefined
    | {
        start: number;
        end: number;
      } {
    if (values.length === 0) {
      return undefined;
    }
    const start = Math.min(...values.map((x) => x.start));
    const end = Math.max(...values.map((x) => x.end));
    return { start, end };
  }

  clone(): InternalAssignment {
    const clone = new InternalAssignment({ ...this.value }, this.day);
    clone.partials = this.partials.map((x) => x.clone());
    return clone;
  }

  interval(): Interval {
    return new Interval(this.start, this.end);
  }

  /**
   * Check if two assignments should be joined because they are close together
   * @param last last assignment
   * @param current current assignment
   * @param threhold duration in minutes
   */
  static shouldJoin(
    last: { end: number; readonly: boolean },
    current: { start: number; readonly: boolean },
    threhold: number
  ): boolean {
    if (last.readonly !== current.readonly) {
      return false;
    }
    return current.start - last.end < threhold;
  }

  static map(assignments: Assignment[][]): InternalAssignment[][] {
    return assignments.map((a, day) =>
      a.map((shift) => new InternalAssignment(shift, day))
    );
  }
}
