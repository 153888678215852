import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';

export const AdminGroupAddressbook = () => {
  const context = useOutletContext<OutletContextAdminGroups>();
  const { t } = useTranslation('admin');
  const i18nPrefix = 'groups.features';

  const { selected } = context;

  return (
    <div>
      <h1>In Arbeit</h1>
    </div>
  );
};
