import { RuleBase, RuleType } from '../rule-base';
import { InternalAssignment } from '../core/internal-assignment';
import { InternalTest } from '../core/internal-test';
import { BlockStatus } from '../rule-models';
import { WeekRestMask } from '../core/week-rest-mask';

export class MinWeeklyRest extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSchedule(
    test: InternalTest,
    plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    if (!this.specs.isEnabled('rest-weekly') || test.readonly) {
      return;
    }

    const afterNonRest = this.specs.getTime('after-x-not-rest', 'end');
    const restDays = this.specs.getNumber('rest-weekly', 'days');
    const mask = new WeekRestMask(afterNonRest, restDays).load(this.days, plan);
    for (const [day] of plan.entries()) {
      const testMask = mask.toTestMask(day, test);
      if (mask.blockOn(day, testMask)) {
        blocked[day].blocked = true;
        blocked[day].notes.push(
          `Eine Wochenruhezeit von ${restDays} aufeinanderfolgenden Tagen ist erforderlich`
        );
      }
    }
  }
}
