import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';

export const setLocale = (hash: string) => {
  const language = localStorage.getItem('persist-lang');
  const lng = language ? language : navigator.language.split('-')[0];

  i18next
    .use(initReactI18next)
    .use(Backend)
    .init<HttpBackendOptions>({
      lng,
      fallbackLng: 'de',
      debug: false,
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json?version=${hash || Date.now()}`
      },
      react: { useSuspense: false }
    });
};
