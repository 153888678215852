import { RuleSpec } from './rule-spec';

export const ruleSpecsRest: RuleSpec[] = [
  {
    id: 'rest-between',
    category: 'rest',
    defaults: [{ key: 'hours', type: 'number', value: '11' }],
    values: [],
    enabled: true
  },
  {
    id: 'rest-weekly',
    category: 'rest',
    defaults: [{ key: 'days', type: 'number', value: '2' }],
    values: [],
    enabled: false
  },
  {
    id: 'after-x-not-rest',
    category: 'rest',
    defaults: [{ key: 'end', type: 'time', value: '09:00' }],
    values: [],
    enabled: false
  },
  {
    id: 'weekend-needs-full',
    category: 'rest',
    defaults: [{ key: 'count', type: 'number', value: '3' }],
    values: [],
    enabled: false
  },
  {
    id: 'weekend-needs-part',
    category: 'rest',
    defaults: [{ key: 'count', type: 'number', value: '6' }],
    values: [],
    enabled: false
  }
];
