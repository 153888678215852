import { useContext, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { emptyAccessMap, IAccessMap } from '@kidsmanager/util-models';

export const TeamDashboard = () => {
  const client = useContext(ClientBackendContext);
  const [accessMap, setAccessMap] = useState<IAccessMap>(emptyAccessMap);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    client.settings.accessMap().then((value) => {
      setSelected(
        value.team.findIndex((tab) => tab.href === window.location.href)
      );
      setAccessMap(value);
    });
  }, [client]);

  return (
    <section>
      <div
        id="tab-hdr"
        className="ml-2 flex items-end gap-7 border-b border-black/30 pt-5 text-black/70"
      >
        <h2 className="inline text-xl font-bold">
          {accessMap.user.currentGroup}
        </h2>
        <ul className="inline-flex items-end gap-5 px-1 text-[1.1em]">
          {accessMap.team.map((tab, index) => (
            <li
              key={index}
              className={`inline-block h-7 border-b-[6px] px-0.5 ${
                index === selected
                  ? 'border-b-secondary text-black/70'
                  : 'border-b-black/30 text-black/30'
              }`}
            >
              <Link to={tab.href} onClick={() => setSelected(index)}>
                {tab.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Outlet />
    </section>
  );
};
