import { quickid } from '@kidsmanager/ui-api';
import {
  Checkbox,
  InlineInput,
  TableMenu,
  SettingsTable
} from '@kidsmanager/ui-core';
import { IRosterTemplateSaveAs } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SaveAsProps {
  label: string;
  options: IRosterTemplateSaveAs[];
  onChange?: (options: IRosterTemplateSaveAs[]) => void;
}

export const SaveAs = (props: SaveAsProps) => {
  const [saveAs, setSaveAs] = useState<IRosterTemplateSaveAs[]>([]);
  const { t } = useTranslation('admin-roster');

  useEffect(() => {
    setSaveAs(props.options);
  }, [props.options]);

  const handleAdd = (e: React.MouseEvent) => {
    setSaveAs((prev) => {
      const next = [
        ...prev,
        {
          id: quickid('SA', 'low'),
          name: t('roster.new_option'),
          description: '',
          abbreviation: '',
          infoOnly: false
        }
      ];
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleDelete = (option: IRosterTemplateSaveAs) => {
    setSaveAs((prev) => {
      const next = prev.filter((o) => o.id !== option.id);
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleMoveUp = (option: IRosterTemplateSaveAs) => {
    setSaveAs((prev) => {
      const index = prev.findIndex((o) => o.id === option.id);
      if (index === 0) return prev;
      const next = [...prev];
      next.splice(index - 1, 0, next.splice(index, 1)[0]);
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleMoveDown = (option: IRosterTemplateSaveAs) => {
    setSaveAs((prev) => {
      const index = prev.findIndex((o) => o.id === option.id);
      if (index === prev.length - 1) return prev;
      const next = [...prev];
      next.splice(index + 1, 0, next.splice(index, 1)[0]);
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleChange = (
    option: IRosterTemplateSaveAs,
    property: string,
    value: string
  ) => {
    setSaveAs((prev) => {
      const next = prev.map((o) =>
        o.id === option.id ? { ...o, [property]: value } : o
      );
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleInfoChange = (option: IRosterTemplateSaveAs, value: boolean) => {
    setSaveAs((prev) => {
      const next = prev.map((o) =>
        o.id === option.id ? { ...o, infoOnly: value } : o
      );
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  return (
    <SettingsTable
      label={props.label}
      columns={[
        { label: t('roster.name'), minWidth: '160px' },
        { label: t('roster.explanation') },
        { label: t('roster.abbr'), width: '80px' },
        { label: t('roster.info_only'), width: '60px' },
        { label: '', width: '40px' }
      ]}
      onAdd={handleAdd.bind(this)}
    >
      {saveAs.map((option, index) => (
        <tr key={option.id} className="my-1 odd:bg-neutral-100">
          <td className="w-[160px]">
            <InlineInput
              value={option.name}
              onChange={(val) => handleChange(option, 'name', val)}
            />
          </td>
          <td>
            <InlineInput
              value={option.description}
              onChange={(val) => handleChange(option, 'description', val)}
            />
          </td>
          <td>
            <InlineInput
              value={option.abbreviation}
              onChange={(val) => handleChange(option, 'abbreviation', val)}
            />
          </td>
          <td className="align-bottom">
            <Checkbox
              checked={option.infoOnly}
              onChange={(val) => handleInfoChange(option, val)}
            />
          </td>
          <td className="text-center">
            <TableMenu
              options={[
                {
                  label: t('common.move_up'),
                  disabled: index === 0,
                  callback: handleMoveUp.bind(this, option)
                },
                {
                  label: t('common.move_down'),
                  disabled: index === saveAs.length - 1,
                  callback: handleMoveDown.bind(this, option)
                },
                {
                  label: t('common.delete'),
                  callback: handleDelete.bind(this, option)
                }
              ]}
            />
          </td>
        </tr>
      ))}
    </SettingsTable>
  );
};
