import { Select, Option, Checkbox, DualRange } from '@kidsmanager/ui-core';
import {
  hasModule,
  IGroupSummary,
  IRosterTemplate,
  IUser,
  Modules,
  ROLE_LABELS
} from '@kidsmanager/util-models';
import { useEffect, useMemo, useState } from 'react';
import { emptyFilter, FilterDefinition } from '../helpers/filter-logic';

export interface UserAdvancedSearchProps {
  groups: IGroupSummary[];
  templates: IRosterTemplate[];
  activeUsers: IUser[];
  value?: FilterDefinition;
  modules: number;
  onSearch?: (filter: FilterDefinition) => void;
}

type DualRange = {
  min: number;
  max: number;
  from: number;
  to: number;
};

export const UserAdvancedSearch = (props: UserAdvancedSearchProps) => {
  const [filter, setFilter] = useState(emptyFilter());
  const [managers, setManagers] = useState<IUser[]>([]);
  const [workingHours, setWorkingHours] = useState<DualRange>();
  const [holidayAllowances, setHolidayAllowances] = useState<DualRange>();
  const recentSearches: { term: string; count: number }[] = [];
  const allRoles = useMemo(
    () =>
      Array.from(ROLE_LABELS.keys())?.map((id) => ({
        id,
        label: ROLE_LABELS.get(id) || id
      })),
    []
  );

  useEffect(() => {
    setManagers(
      props.activeUsers
        .filter((u) => u.roles?.some((r) => r === 'leader' || r === 'manager'))
        .filter((u) => !!u.lastName)
        .sort((a, b) => (a.lastName || '').localeCompare(b.lastName || ''))
    );
    const extractDualRange = (values: number[]) => {
      const min = Math.min(...values);
      const max = Math.max(...values);
      return { min, max, from: min, to: max };
    };
    setWorkingHours(
      extractDualRange(props.activeUsers.map((u) => u.hoursPerWeek || 0))
    );
    setHolidayAllowances(
      extractDualRange(props.activeUsers.map((u) => u.holidayAllowance || 0))
    );
  }, [props.activeUsers]);

  useEffect(() => {
    if (props.value) {
      setFilter(props.value);
    }
  }, [props.value]);

  const applyChange = (change: FilterDefinition) => {
    setFilter(change);
    props.onSearch?.(change);
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    applyChange({ ...filter, group: e.target.value || undefined });
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    applyChange({ ...filter, role: e.target.value || undefined });
  };

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    applyChange({ ...filter, template: e.target.value || undefined });
  };

  const handleManagerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    applyChange({ ...filter, manager: e.target.value || undefined });
  };

  const handleCheckedChange = (key: string, value: boolean) => {
    applyChange({ ...filter, [key]: value });
  };
  const handleEmailCheckedChange = (key: string, value: boolean) => {
    const update = structuredClone(filter);
    update.email = { ...update.email, [key]: value };
    applyChange(update);
  };

  const handleWorkingHoursChange = (from: number, to: number) => {
    if (!workingHours) {
      return;
    }
    if (workingHours.min === from && workingHours.max === to) {
      applyChange({ ...filter, hours: undefined });
    } else if (workingHours.min === from) {
      applyChange({ ...filter, hours: { from: undefined, to } });
    } else if (workingHours.max === to) {
      applyChange({ ...filter, hours: { from, to: undefined } });
    } else {
      applyChange({ ...filter, hours: { from, to } });
    }
  };

  const handleAllowanceChange = (from: number, to: number) => {
    if (!holidayAllowances) {
      return;
    }
    if (holidayAllowances.min === from && holidayAllowances.max === to) {
      applyChange({ ...filter, holidays: undefined });
    } else if (holidayAllowances.min === from) {
      applyChange({ ...filter, holidays: { from: undefined, to } });
    } else if (holidayAllowances.max === to) {
      applyChange({ ...filter, holidays: { from, to: undefined } });
    } else {
      applyChange({ ...filter, holidays: { from, to } });
    }
  };

  return (
    <>
      <div className="grid grid-cols-12 items-center gap-x-4 gap-y-5 pb-10">
        <div className="col-span-4 text-sm text-black/60 lg:text-base">
          Gruppe
        </div>
        <div className="col-span-8">
          <Select
            onChange={handleGroupChange.bind(this)}
            defaultValue={props.value?.group || ''}
          >
            <Option value="">Alle Gruppen</Option>
            {props.groups.map((group) => (
              <Option key={group.groupId} value={group.name}>
                {group.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-span-4 text-sm text-black/60 lg:text-base">
          Rolle
        </div>
        <div className="col-span-8">
          <Select
            onChange={handleRoleChange.bind(this)}
            defaultValue={props.value?.role || ''}
          >
            <Option value="">Alle Rollen</Option>
            {allRoles.map((role) => (
              <Option key={role.id} value={role.label}>
                {role.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-span-4 text-sm text-black/60 lg:text-base">
          Dienstvorlage
        </div>
        <div className="col-span-8">
          <Select
            onChange={handleTemplateChange.bind(this)}
            defaultValue={props.value?.template || ''}
          >
            <Option value="">Alle Dienstvorlagen</Option>
            <Option value="ohne">Ohne Dienstvorlagen</Option>
            {props.templates.map((template) => (
              <Option key={template.id} value={template.name}>
                {template.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-span-4 text-sm text-black/60 lg:text-base">
          Vorgesetzete/r
        </div>
        <div className="col-span-8">
          <Select
            onChange={handleManagerChange.bind(this)}
            defaultValue={props.value?.manager || ''}
          >
            <Option value="">Alle Vorgesetzete</Option>
            <Option value="ohne">Ohne Vorgesetzete</Option>
            {managers.map((manager, index) => (
              <Option key={index} value={manager.lastName || ''}>
                {`${manager.lastName}, ${manager.firstName}`}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-span-4 text-sm text-black/60 lg:text-base">
          E-Mail
        </div>
        <div className="col-span-8 flex justify-between">
          <Checkbox
            checked={!!props.value?.email.verified}
            onChange={handleEmailCheckedChange.bind(this, 'verified')}
          >
            Best&auml;tigt
          </Checkbox>
          <Checkbox
            checked={!!props.value?.email.unverified}
            onChange={handleEmailCheckedChange.bind(this, 'unverified')}
          >
            Unbest&auml;tigt
          </Checkbox>
          <Checkbox
            checked={!!props.value?.email.none}
            onChange={handleEmailCheckedChange.bind(this, 'none')}
          >
            Keine
          </Checkbox>
        </div>
        <div className="col-span-4 text-sm text-black/60 lg:text-base">
          Zugang
        </div>
        <div className="col-span-8">
          <Checkbox
            checked={!!props.value?.locked}
            onChange={handleCheckedChange.bind(this, 'locked')}
          >
            Gesperrt
          </Checkbox>
        </div>
        {hasModule(props.modules, Modules.Timesheets) && workingHours && (
          <>
            <div className="col-span-4 text-sm text-black/60 lg:text-base">
              Wochenstunden
            </div>
            <div className="col-span-8">
              <DualRange
                {...workingHours}
                onChange={handleWorkingHoursChange.bind(this)}
              />
            </div>
          </>
        )}
        {hasModule(props.modules, Modules.Timesheets) && holidayAllowances && (
          <>
            <div className="col-span-4 text-sm text-black/60 lg:text-base">
              Urlaub
            </div>
            <div className="col-span-8">
              <DualRange
                {...holidayAllowances}
                onChange={handleAllowanceChange.bind(this)}
              />
            </div>
          </>
        )}
      </div>
      {recentSearches.length > 0 && (
        <div className="border-t pt-6">
          <h3 className="mb-4 text-black/60">Zuletzt gesucht</h3>
          <ul>
            {recentSearches.map((search) => (
              <li className="my-3 flex border-b pb-1" key={search.term}>
                <span className="flex-1 text-black/80">{search.term}</span>
                <span className="text-sm text-black/60">
                  {search.count} MitarbeiterInnen
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
