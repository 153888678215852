import { IMembership, IMemberStats } from '@kidsmanager/util-models';
import { TeamMember } from '../models';

export const compileTeam = (
  members: IMembership[],
  stats: IMemberStats[],
  holidays: { id: string; days: number }[]
): TeamMember[] => {
  return members.map((member) => {
    const extended = { ...member, carryOver: 0, holidays: 0 };
    const stat = stats.find((s) => s.id === member.id);
    if (stat) {
      extended.carryOver = stat.carryOver;
    }
    const holiday = holidays.find((h) => h.id === member.id);
    if (holiday) {
      extended.holidays = holiday.days;
    }
    return extended;
  });
};
