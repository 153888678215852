import { Input, Option, Select } from '@kidsmanager/ui-core';
import { IUser } from '@kidsmanager/util-models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminUser } from './outlet-context-admin-user';
import {
  minUsernameLength,
  validateUsername,
  validateEmail
} from './helpers/validators';
import { EmailStatus } from './components/email-status';
import { useTranslation } from 'react-i18next';

export const AdminUserProfile = () => {
  const userForm = useRef<HTMLFormElement>(null);
  const context = useOutletContext<OutletContextAdminUser>();

  const { selectedUser, groups } = context;
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [usernameHint, setUsernameHint] = useState<string>();
  const [emailHint, setEmailHint] = useState<string>();
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const { t } = useTranslation('admin');
  const i18nPrefix = 'users.profile';

  useEffect(() => {
    if (selectedUser.userId !== context.userId) {
      setIsEmailDirty(false);
      setEmailHint(undefined);
      setUsernameHint(undefined);
      setEmailTouched(false);
    }
  }, [selectedUser.userId, context.userId]);

  useEffect(() => {
    const handleReset = (event: unknown) => {
      userForm.current?.reset();
    };
    document.addEventListener('adminUserAction', handleReset);
    return () => window.removeEventListener('adminUserAction', handleReset);
  }, []);

  const activeUsernames = useMemo(
    () => context.activeUsers?.map((u) => u.username || ''),
    [context.activeUsers]
  );
  const lockedUsernames = useMemo(
    () => context.lockedUsers?.map((u) => u.username || ''),
    [context.lockedUsers]
  );
  const managers = useMemo(
    () =>
      context.activeUsers
        ?.filter((u) => u.roles?.some((r) => r === 'leader' || r === 'manager'))
        .filter((u) => u.userId !== selectedUser.userId)
        .map((u) => ({
          id: u.userId,
          name: `${u.lastName}, ${u.firstName}`
        })) || [],
    [context.activeUsers, selectedUser.userId]
  );

  const getUserFromForm = (formEvent: HTMLFormElement): IUser | undefined => {
    const data = new FormData(formEvent);
    return {
      userId: selectedUser.userId,
      username: context.newUser
        ? data.get('username')?.toString()
        : selectedUser.username,
      lastName: data.get('lastName')?.toString(),
      firstName: data.get('firstName')?.toString(),
      phone: data.get('phone')?.toString(),
      email: data.get('email')?.toString(),
      managerId: data.get('managerId')?.toString(),
      primaryGroupId: data.get('primaryGroupId')?.toString()
    } as IUser;
  };

  const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
    const formUser = getUserFromForm(e.currentTarget);
    const usernameWarning = context.newUser
      ? validateUsername(
          formUser?.username,
          activeUsernames,
          lockedUsernames,
          minUsernameLength
        )
      : undefined;
    const emailWarning = emailTouched
      ? validateEmail(formUser?.email)
      : undefined;

    setUsernameHint(usernameWarning);
    setEmailHint(emailWarning);
    setIsEmailDirty(selectedUser.email !== formUser?.email);
    if (formUser) {
      context.onUserChanged(formUser, !!usernameWarning || !!emailWarning);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (e.target.name === 'email') {
      setEmailTouched(true);
    }
    handleOnChange(e);
  };

  const handleReset = () => {
    if (!context.newUser) {
      setIsEmailDirty(false);
    }
  };

  return (
    <form
      ref={userForm}
      autoComplete="off"
      key={selectedUser.userId}
      className="flex flex-col gap-3"
      onBlur={handleBlur.bind(this)}
      onChange={handleOnChange.bind(this)}
      onReset={handleReset.bind(this)}
    >
      {context.newUser && (
        <div className="relative">
          <Input
            name="username"
            label={t(`${i18nPrefix}.username.label`)}
            placeholder={t(`${i18nPrefix}.username.placeholder`)}
            spellCheck={false}
            mask="username"
            hint={usernameHint}
          />
        </div>
      )}
      <Input
        name="firstName"
        label="Vorname"
        defaultValue={selectedUser.firstName}
      />
      <Input
        name="lastName"
        label="Nachname"
        defaultValue={selectedUser.lastName}
      />
      <Input
        name="phone"
        label="Telefonnummer"
        defaultValue={selectedUser.phone}
        mask="phone"
      />
      <Input
        name="email"
        label="E-Mail"
        defaultValue={selectedUser.email}
        mask="email"
        hint={emailHint}
        suffix={
          <EmailStatus isDirty={isEmailDirty} value={context.emailState} />
        }
      />
      <Select
        name="managerId"
        label="Vorgesetzte&#47;r"
        defaultValue={selectedUser.managerId}
      >
        <Option value="">-- Kein Vorgesetzter --</Option>
        {managers?.map((m) => (
          <Option key={m.id} value={m.id}>
            {m.name}
          </Option>
        ))}
      </Select>

      {groups.length > 0 && (
        <Select
          name="primaryGroupId"
          label="Primärgruppe"
          defaultValue={selectedUser.primaryGroupId}
        >
          <Option key="" value="">
            -- Keine Gruppe --
          </Option>
          {groups.map((g) => (
            <Option key={g.groupId} value={g.groupId}>
              {g.name}
            </Option>
          ))}
        </Select>
      )}
    </form>
  );
};
