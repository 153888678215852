export class MonthSet {
  prev = { year: 0, month: 0 };
  current = { year: 0, month: 0 };
  next = { year: 0, month: 0 };

  constructor(value?: string) {
    if (!value) {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      this.setCurrent(year, month);
      this.setPrev(year, month);
      this.setNext(year, month);
    } else {
      const [year, month] = value.split('-').map(Number);
      this.setCurrent(year, month);
      this.setPrev(year, month);
      this.setNext(year, month);
    }
  }

  private setCurrent(year: number, month: number): void {
    this.current = { year, month };
  }

  private setPrev(currentYear: number, currentMonth: number): void {
    this.prev = {
      year: currentMonth === 1 ? currentYear - 1 : currentYear,
      month: currentMonth === 1 ? 12 : currentMonth - 1
    };
  }
  private setNext(currentYear: number, currentMonth: number): void {
    this.next = {
      year: currentMonth === 12 ? currentYear + 1 : currentYear,
      month: currentMonth === 12 ? 1 : currentMonth + 1
    };
  }
}
