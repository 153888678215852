import { Search } from '@kidsmanager/ui-core';
import { UserAdvancedSearch } from './user-advanced-search';
import {
  IGroupSummary,
  IRosterTemplate,
  IUser
} from '@kidsmanager/util-models';
import { FilterDefinition, FilterLogic } from '../helpers/filter-logic';
import { useState } from 'react';

export interface UserSearchProps {
  modules: number;
  groups: IGroupSummary[];
  templates: IRosterTemplate[];
  activeUsers: IUser[];
  onSearch?: (filter?: FilterDefinition) => void;
  onSubmit?: () => void;
}

export const UserSearch = (props: UserSearchProps) => {
  const [searchExpression, setSearchExpression] = useState('');
  const [filterDef, setFilterDef] = useState<FilterDefinition>();

  const handleSimpleSearch = (expression: string) => {
    const def = FilterLogic.fromExpression(expression);
    setSearchExpression(expression);
    setFilterDef(def);
    props.onSearch?.(def);
  };

  const handleAdvancedSearch = (value: FilterDefinition) => {
    setSearchExpression(FilterLogic.toExpression(value));
    setFilterDef(value);
    props.onSearch?.(value);
  };

  const handleSearchClear = () => {
    setTimeout(() => props.onSearch?.());
  };

  return (
    <Search
      id="kidsmgr-user"
      placeholder="Filter nach Name..."
      value={searchExpression}
      onSearch={handleSimpleSearch.bind(this)}
      onClear={handleSearchClear.bind(this)}
      onSubmit={props.onSubmit?.bind(this)}
    >
      <UserAdvancedSearch
        modules={props.modules}
        groups={props.groups}
        templates={props.templates}
        activeUsers={props.activeUsers}
        value={filterDef}
        onSearch={handleAdvancedSearch.bind(this)}
      />
    </Search>
  );
};
