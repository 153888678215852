/* eslint-disable @typescript-eslint/no-unused-vars */

import { InternalAssignment } from './core/internal-assignment';
import { InternalTest } from './core/internal-test';
import { BlockStatus } from './rule-models';
import { RuleSpecs } from './specs/rule-specs';

export const InMins24Hrs = 24 * 60;

export interface DayEntry {
  startSeconds: number;
  durationSeconds: number;
  breakDurationSeconds: number;
  infoOnly: boolean;
  start(): Date;
  end(): Date;
}

export interface DayData {
  date: Date;
  entries: DayEntry[];
}

export interface WorkHistory {
  previous: DayData[];
  current: DayData[];
  next: DayData[];
}

export interface RulesEngineResult {
  status: 'ok' | 'warn' | 'fail';
  message?: string;
}

// Rule types are used by rules engine for the three stages
export enum RuleType {
  prepare = 1, // Rules about combining shifts (e.g., <11 hours break)
  schedule = 2, // Rules about how shifts are scheduled as a whole
  partition = 4, // Rules about how shifts are divided into parts on save
  other = 8 // Rules that are not used by the engine
}

export abstract class RuleBase {
  protected specs: RuleSpecs = new RuleSpecs();
  protected publicHols: Date[] = [];
  protected days: Date[] = [];

  setContext(
    specs: RuleSpecs,
    publicHols: Date[] = [],
    days: Date[] = []
  ): RuleBase {
    this.specs = specs;
    this.publicHols = publicHols;
    this.days = days;
    return this;
  }

  type(): RuleType {
    return RuleType.other;
  }

  prepare(plan: InternalAssignment[][]): void {
    // do nothing by default
  }

  canSchedule(
    test: InternalTest,
    plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    // do nothing by default
  }

  canSave(from: Date, until: Date, history?: WorkHistory): RulesEngineResult {
    return { status: 'ok' };
  }
}
