export interface TransposeToggleProps {
  onClick?: () => void;
}
export const TransposeToggle = (props: TransposeToggleProps) => {
  return (
    <svg
      className="absolute right-2 top-12 cursor-pointer fill-none stroke-black/15 hover:stroke-black/60"
      onClick={props.onClick}
      width={24}
      strokeWidth={2.5}
      viewBox="0 0 24 24"
    >
      <rect x={10} y={2} width={12} height={6} rx={2} ry={2} />
      <rect x={2} y={10} width={6} height={12} rx={2} ry={2} />
      <path d="M12,19 L19,19 L19,12" />
    </svg>
  );
};
