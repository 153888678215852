import { RuleConfig, RuleSpec } from './rule-spec';
import { ruleSpecsDaily } from './rule-specs-daily';
import { ruleSpecsWeekly } from './rule-specs-weekly';
import { ruleSpecsRest } from './rule-specs-rest';
import { ruleSpecsOther } from './rule-specs-other';
import { RuleSpecName, RuleSpecValue } from './rule-name';

export class RuleSpecs {
  _specs: RuleSpec[];

  constructor() {
    this._specs = [
      ...ruleSpecsDaily,
      ...ruleSpecsWeekly,
      ...ruleSpecsRest,
      ...ruleSpecsOther
    ];
  }

  byCategory(): Map<string, RuleSpec[]> {
    const ruleMap = new Map<string, RuleSpec[]>();
    this._specs.forEach((spec) => {
      if (!ruleMap.has(spec.category)) {
        ruleMap.set(spec.category, []);
      }
      ruleMap.get(spec.category)?.push({
        id: spec.id,
        category: spec.category,
        values: spec.values || spec.defaults,
        enabled: spec.enabled || false
      } as RuleSpec);
    });
    return ruleMap;
  }

  configure(config?: RuleConfig[], enableDefault = false): RuleSpecs {
    this._specs.forEach((spec) => {
      const setting = config?.find((x) => x.id === spec.id);
      const values = setting?.values || spec.defaults;
      spec.values = values;
      spec.enabled = setting?.enabled || enableDefault;
    });
    return this;
  }

  getValue(rule: RuleSpecName, key: RuleSpecValue): string {
    const spec = this._specs.find((s) => s.id === rule);
    const value = spec?.values.find((v) => v.key === key)?.value;
    return value
      ? value
      : spec?.defaults.find((d) => d.key === key)?.value || '';
  }

  getNumber(rule: RuleSpecName, key: RuleSpecValue): number {
    return Number(this.getValue(rule, key));
  }

  getTime(rule: RuleSpecName, key: RuleSpecValue): { h: number; m: number } {
    const value = this.getValue(rule, key);
    const [h, m] = value.split(':').map((x) => Number(x));
    return { h, m };
  }
  getTimeRelativeTo(
    rule: RuleSpecName,
    key: RuleSpecValue,
    rel: { h: number; m: number }
  ): { h: number; m: number } {
    const value = this.getValue(rule, key);
    const [h, m] = value.split(':').map((x) => Number(x));
    if (h * 60 + m < rel.h * 60 + rel.m) {
      return { h: h + 24, m };
    }
    return { h, m };
  }

  isEnabled(rule: RuleSpecName): boolean {
    return rule
      ? !!this._specs.find((s) => s.id === rule)?.enabled
      : this._specs[0].enabled;
  }
}
