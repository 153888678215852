import { InMins24Hrs } from '../rule-base';

const InMins12Hrs = 12 * 60;

export class FromTo {
  private fromMins: number;
  private toMins: number;

  constructor(from?: { h: number; m: number }, to?: { h: number; m: number }) {
    this.fromMins = from ? from.h * 60 + from.m : 0;
    this.toMins = to ? to.h * 60 + to.m : 0;
    if (isNaN(this.fromMins) || isNaN(this.toMins)) {
      throw new Error('Invalid from/to times');
    }
  }

  beforeMidnight(value: { day: number; start: number; end: number }): number {
    const { day, start, end } = value;
    const midnight = (day + 1) * InMins24Hrs;
    if (this.fromMins === this.toMins) {
      //no ignore period
      return Math.min(end, midnight) - start;
    } else if (this.fromMins > InMins12Hrs && this.toMins > InMins12Hrs) {
      //period starts before and ends before midnight
      return Math.min(end, this.toMins) - start;
    } else if (this.fromMins > InMins12Hrs) {
      //oncall starts before midnight
      return Math.min(end, midnight, day * InMins24Hrs + this.fromMins) - start;
    } else {
      //oncall starts on or after midnight
      return Math.min(end, midnight, midnight + this.fromMins) - start;
    }
  }

  afterMidnight(value: { day: number; start: number; end: number }): number {
    const { day, end } = value;
    const midnight = (day + 1) * InMins24Hrs;
    if (this.fromMins === this.toMins) {
      //no ignore period
      return Math.max(midnight, end) - midnight;
    } else if (this.fromMins > InMins12Hrs && this.toMins > InMins12Hrs) {
      //period starts before and ends before midnight
      return Math.max(midnight, end) - midnight;
    } else {
      //oncall starts before midnight
      const afterMidnightOrIgnoreEnd = midnight + this.toMins;
      return Math.max(afterMidnightOrIgnoreEnd, end) - afterMidnightOrIgnoreEnd;
    }
  }

  /**
   * returns the duration in hours of all intervals per day
   * @returns array of durations, the index is the day relative to the first assignment
   */
  durations(
    values: { day: number; start: number; end: number; readonly: boolean }[]
  ): {
    before: number;
    after: number;
  } {
    const result: { before: number; after: number } = { before: 0, after: 0 };
    for (const value of values) {
      if (value.readonly) {
        continue;
      }
      const minsBeforeMidnight = this.beforeMidnight(value);
      const minsAfterMidnight = this.afterMidnight(value);
      result.before += minsBeforeMidnight / 60.0;
      result.after += minsAfterMidnight / 60.0;
    }
    return result;
  }
}
