import { IShiftSpec } from '@kidsmanager/util-models';
import { SelectableShiftSpec } from '../models';
import { symbolize } from '@kidsmanager/util-common';

export const KeyHoliday = '-holiday-';
export const KeyUnavailable = '-unavailable-';

export const standardShifts = (): SelectableShiftSpec[] => {
  return [
    {
      index: -2,
      id: KeyHoliday,
      name: 'Urlaub',
      color: '#e0e0e0',
      symbol: 'U',
      start: '00:00',
      end: '23:59',
      hrs: 24,
      infoOnly: true,
      tip: 'Urlaub muss vom Teammitglied hinzugefügt werden und kann hier nicht eingetragen werden.',
      readonly: true,
      created: '2020-01'
    },
    {
      index: -1,
      id: KeyUnavailable,
      name: 'Nicht verfügbar',
      color: '#e0e0e0',
      symbol: 'X',
      start: '00:00',
      end: '23:59',
      hrs: 24,
      readonly: true,
      created: '2020-01'
    }
  ];
};

export const compileShifts = (
  shifts: IShiftSpec[],
  standard: SelectableShiftSpec[]
): SelectableShiftSpec[] => {
  const clone: SelectableShiftSpec[] = structuredClone(shifts);
  symbolize(clone);
  const sorted = sortShifts(clone) as SelectableShiftSpec[];
  sorted[0].selected = true;
  return [...standard, ...sorted];
};

const sortShifts = (value: IShiftSpec[]) => {
  const result: IShiftSpec[] = [];

  type GrouppedShiftLookup = {
    [key: string]: {
      shifts: SelectableShiftSpec[];
      hrs: number;
      start: string;
    };
  };

  //group by first letter of name
  const grouped = Object.values(
    value.reduce((acc, shift) => {
      const key = shift.name.charAt(0).toUpperCase();
      if (!acc[key]) {
        acc[key] = { shifts: [shift], hrs: 0, start: shift.start };
      } else {
        acc[key].shifts.push(shift);
      }
      return acc;
    }, {} as GrouppedShiftLookup)
  );

  //calculate max hours and earliest start time
  for (const group of grouped) {
    group.hrs = group.shifts.reduce(
      (max, shift) => Math.max(max, shift.hrs),
      0
    );
    group.start = group.shifts.reduce(
      (earliest, x) => (x.start < earliest ? x.start : earliest),
      group.start
    );
  }

  //sort by max hours and earliest start time
  const sortedByMaxHrs = grouped.sort((a, b) => b.hrs - a.hrs);
  for (const group of sortedByMaxHrs) {
    group.shifts.sort((a, b) => (a.start > b.start ? 1 : -1));
    result.push(...group.shifts);
  }

  return result;
};
