export interface LinkButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const LinkButton = (props: LinkButtonProps) => {
  return (
    <button
      className="hover:text-secondary outline-focus rounded-md px-1 pb-1 text-sm underline underline-offset-4 outline-offset-4 disabled:opacity-40"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
