import { RuleBase, RuleType } from '../rule-base';
import { BlockStatus } from '../rule-models';
import { InternalTest } from '../core/internal-test';
import { InternalAssignment } from '../core/internal-assignment';
import { Interval } from '../core/interval';

export class ApprovalBeforeX extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSchedule(
    test: InternalTest,
    _plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    if (!this.specs.isEnabled('before-x-approval')) {
      return;
    }
    const spec = this.specs.getTime('before-x-approval', 'before');
    const before = Interval.create(spec).start;
    if (test.start(0) < before) {
      const note = `Arbeiten vor ${this.specs.getValue('before-x-approval', 'before')} ist nur mit Freigabe der Leitung erlaubt`;
      blocked.forEach((b) => {
        b.blocked = true;
        b.notes.push(note);
      });
    }
  }
}
