import { RuleBase, RuleType } from '../rule-base';
import { InternalAssignment } from '../core/internal-assignment';

export class RestBetween extends RuleBase {
  type(): RuleType {
    return RuleType.prepare;
  }

  prepare(plan: InternalAssignment[][]): void {
    if (!this.specs.isEnabled('rest-between')) {
      return;
    }

    const minsBetween = this.specs.getNumber('rest-between', 'hours') * 60;
    let last: InternalAssignment | null = null;
    for (const day of plan) {
      for (let i = 0; i < day.length; i++) {
        if (last === null) {
          last = day[i];
          continue;
        }
        if (InternalAssignment.shouldJoin(last, day[i], minsBetween)) {
          last.setEnd(day[i].end);
          last.partials.push(...day.splice(i, 1));
          i--;
        } else {
          last = day[i];
        }
      }
    }
  }
}
