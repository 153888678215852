import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { FeatureCard } from './components/feature-card';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';

enum FeatureOption {
  'calendar',
  'notes',
  'drive',
  'medication',
  'roster'
}
export const AdminGroupFeatures = () => {
  const context = useOutletContext<OutletContextAdminGroups>();
  const { t } = useTranslation('admin');
  const i18nPrefix = 'groups.features';

  const { selected } = context;

  const onChange = (option: FeatureOption, checked: boolean) => {
    if (selected) {
      switch (option) {
        case FeatureOption.calendar:
          selected.features.calendar = checked;
          break;
        case FeatureOption.notes:
          selected.features.notes = checked;
          break;
        case FeatureOption.drive:
          selected.features.drive = checked;
          break;
        case FeatureOption.medication:
          selected.features.medication = checked;
          break;
        case FeatureOption.roster:
          selected.features.roster = checked;
          break;
      }
      context.onChange?.();
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <FeatureCard
        titleKey={`${i18nPrefix}.calendar.title`}
        descriptionKey={t(`${i18nPrefix}.calendar.description`)}
        checked={selected.features.calendar || false}
        onChange={onChange.bind(this, FeatureOption.calendar)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="3rem"
          viewBox="0 -960 960 960"
          fill="#5f6368"
        >
          <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
        </svg>
      </FeatureCard>
      <FeatureCard
        titleKey={`${i18nPrefix}.cloud.title`}
        descriptionKey={t(`${i18nPrefix}.cloud.description`)}
        settingsLink={'drive/settings'}
        checked={selected.features.drive || false}
        onChange={onChange.bind(this, FeatureOption.drive)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="3rem"
          viewBox="0 -960 960 960"
          fill="#5f6368"
        >
          <path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z" />
        </svg>{' '}
      </FeatureCard>
      <FeatureCard
        titleKey={`${i18nPrefix}.notes.title`}
        descriptionKey={t(`${i18nPrefix}.notes.description`)}
        checked={selected.features.notes || false}
        onChange={onChange.bind(this, FeatureOption.notes)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="3rem"
          viewBox="0 -960 960 960"
          fill="#5f6368"
        >
          <path d="M160-400v-80h280v80H160Zm0-160v-80h440v80H160Zm0-160v-80h440v80H160Zm360 560v-123l221-220q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8 9 12.5 20t4.5 22q0 11-4 22.5T863-380L643-160H520Zm300-263-37-37 37 37ZM580-220h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z" />
        </svg>
      </FeatureCard>
      <FeatureCard
        titleKey={`${i18nPrefix}.medication.title`}
        descriptionKey={t(`${i18nPrefix}.medication.description`)}
        settingsLink={'medication/settings'}
        checked={selected.features.medication || false}
        onChange={onChange.bind(this, FeatureOption.medication)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="3rem"
          viewBox="0 -960 960 960"
          fill="#5f6368"
        >
          <path d="M120-760v-80h480v80H120Zm180 500h120v-100h100v-120H420v-100H300v100H200v120h100v100ZM160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h400q33 0 56.5 23.5T640-640v440q0 33-23.5 56.5T560-120H160Zm0-80h400v-440H160v440Zm600-210q-35-17-57.5-56.5T680-560q0-68 34.5-114t85.5-46q51 0 85.5 46T920-560q0 54-22.5 93.5T840-410v250q0 17-11.5 28.5T800-120q-17 0-28.5-11.5T760-160v-250Zm40-70q12 0 26-21.5t14-58.5q0-37-14-58.5T800-640q-12 0-26 21.5T760-560q0 37 14 58.5t26 21.5Zm-440 60Zm440-140Z" />
        </svg>
      </FeatureCard>
      <FeatureCard
        titleKey={`${i18nPrefix}.roster.title`}
        descriptionKey={t(`${i18nPrefix}.roster.description`)}
        checked={selected.features.roster || false}
        onChange={onChange.bind(this, FeatureOption.roster)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="3rem"
          viewBox="0 -960 960 960"
          fill="#5f6368"
        >
          <path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
        </svg>
      </FeatureCard>
    </div>
  );
};
