import './load-spinner.css';

export interface LoadSpinnerProps {
  show: boolean;
  position?: 'left' | 'right';
  children?: React.ReactNode;
}

export const LoadSpinner = (props: LoadSpinnerProps) => {
  return (
    <div className="relative inline-block">
      <div
        className={`absolute top-1/2 -translate-y-1/2 ${props.position === 'right' ? 'right-0 translate-x-full' : '-translate-x-full'}`}
      >
        <div
          className="load-spinner text-secondary"
          style={{ display: props.show ? 'block' : 'none' }}
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      {props.children}
    </div>
  );
};
