export interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  color?: 'primary' | 'default' | 'accent' | 'white';
  onClick?: () => void;
}

/**
 * Should be used for all standard buttons in the application, to ensure a consistent look and feel.
 */
export const Button = (props: ButtonProps) => {
  const color = props.color || 'default';

  if (props.disabled) {
    return (
      <button
        disabled={true}
        className={`border-silver-200 bg-silver-100 text-silver-950/25 rounded-md border px-2 py-1 text-sm ${props.className}`}
      >
        {props.children}
      </button>
    );
  }
  if (props.selected) {
    return (
      <button
        type={props.type}
        onClick={props.onClick}
        className={`border-silver-400 outline-focus rounded-md border bg-lime-500 px-2 py-1 text-sm text-white outline-offset-4 filter hover:brightness-90 active:bg-lime-600 ${props.className}`}
      >
        {props.children}
      </button>
    );
  }
  switch (props.color) {
    case 'primary':
      return (
        <button
          type={props.type}
          onClick={props.onClick}
          className={`border-primary outline-focus bg-primary text-contrast rounded-md border px-2 py-1 text-sm outline-offset-4 filter hover:brightness-90 ${props.className}`}
        >
          {props.children}
        </button>
      );
    case 'accent':
      return (
        <button
          type={props.type}
          onClick={props.onClick}
          className={`border-accent outline-focus bg-accent rounded-md border px-2 py-1 text-sm font-medium text-white outline-offset-4 filter hover:brightness-90 ${props.className}`}
        >
          {props.children}
        </button>
      );
    case 'white':
      return (
        <button
          type={props.type}
          onClick={props.onClick}
          className={`outline-focus text-silver-950 hover:bg-silver-200 active:bg-silver-500 rounded-md border bg-white px-2 py-1 text-sm outline-offset-4 ${props.className}`}
        >
          {props.children}
        </button>
      );
    default:
      return (
        <button
          type={props.type}
          onClick={props.onClick}
          className={`border-silver-400 outline-focus text-silver-950 hover:bg-silver-200 active:bg-silver-500 rounded-md border bg-white px-2 py-1 text-sm outline-offset-4 ${props.className}`}
        >
          {props.children}
        </button>
      );
  }
};
