export interface UserData {
  id: string;
  dutyRosters: string;
  name: string;
  contractedHrs: number;
  holidays: number;
  workingDays: number;
  hoursInMonth: number;
  carryOver: number;
  adjustment: number;
  goal: number;
  available: number;
  warn?: 'low' | 'high';
}

export const emptyUserData: UserData = {
  id: '',
  dutyRosters: '',
  name: '',
  contractedHrs: 0,
  holidays: 0,
  workingDays: 0,
  hoursInMonth: 0,
  carryOver: 0,
  adjustment: 0,
  goal: 0,
  available: 0
};
