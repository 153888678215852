export class Interval {
  /**
   * Specifies an interval of time in minutes from start of month
   * @param start number of minutes from start of month
   * @param end number of minutes from start of month
   */
  constructor(
    public start: number,
    public end: number
  ) {}

  static create(
    start: { d?: number; h: number; m: number },
    duration?: { h?: number; m?: number }
  ): Interval {
    const value = (start.d || 0) * 24 * 60 + start.h * 60 + start.m;
    const durMins = duration ? (duration.h || 0) * 60 + (duration.m || 0) : 0;
    return new Interval(value, value + durMins);
  }

  static order(a: Interval, b: Interval): Interval[] {
    return a.start < b.start ? [a, b] : [b, a];
  }

  static restMinutes(a: Interval, b: Interval): number {
    return a.start < b.start ? b.start - a.end : a.start - b.end;
  }

  static totalMinutes(a: Interval, b: Interval): number {
    return a.start < b.start ? b.end - a.start : a.end - b.start;
  }

  static totalHours(a: Interval, b: Interval): number {
    return Interval.totalMinutes(a, b) / 60;
  }

  endsNextDay() {
    const dayStart = Math.floor(this.start / (24 * 60));
    const dayEnd = Math.floor(this.end / (24 * 60));
    return dayStart + 1 === dayEnd;
  }

  overlapsWith(value: Interval): boolean {
    return this.start < value.end && this.end > value.start;
  }

  minutes(): number {
    return this.end - this.start;
  }

  hours(): number {
    return this.minutes() / 60;
  }

  join(value: Interval): Interval {
    return this.start < value.start
      ? new Interval(this.start, value.end)
      : new Interval(value.start, this.end);
  }

  contains(...values: { h: number; m: number }[]): boolean {
    for (const value of values) {
      const days = Math.floor(this.start / (24 * 60));
      const timestamp = days * 24 * 60 + value.h * 60 + value.m;
      if (!(this.start <= timestamp && this.end >= timestamp)) {
        return false;
      }
    }
    return true;
  }
}
