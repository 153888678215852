import { Role } from '@kidsmanager/util-storage';
import { Jwt } from './jwt';
import { IDP, TokenExchangeResponse } from '@kidsmanager/util-models';
import { Modules } from '@kidsmanager/util-models';
import { quickid } from './id';

export type fetch = (url: string, init?: RequestInit) => Promise<Response>;

export type AuthState =
  | 'Unauthenticated'
  | 'Authenticated'
  | 'MfaChallenge'
  | 'UserNotConfirmed'
  | 'EmailUnverified';

export type SignupResponse = 'Success' | 'UserExists' | 'UnknownError';
export type ConfirmResponse =
  | 'Success'
  | 'ExpiredCode'
  | 'CodeMismatch'
  | 'UnknownError';
export type PasswordResponse = 'Success' | 'UnknownError';

export interface IAuth {
  authenticateUsernamePassword(
    username: string,
    password: string,
    rememberMe?: boolean
  ): Promise<{
    state: AuthState;
    type?: 'email' | 'authenticator';
    challenge?: string;
  }>;

  processAuthResponse(
    response: TokenExchangeResponse,
    rememberMe: boolean
  ): void;

  refresh(): Promise<AuthState>;

  verifyEmail(tenant: string, token: string): Promise<void>;

  expiresAt(): number;

  isAuthenticated(): boolean;

  modules(): Modules;

  inRole(role: Role): boolean;

  requiresSsoElevation(): boolean;

  logout(): void;

  email(): string | null;

  token(): string | null;

  idp(): IDP | null;

  refreshToken(): string | null;

  updateTokens(
    token?: string,
    refresh_token?: string,
    rememberMe?: boolean
  ): void;

  updateTenant(tenant: string): void;

  displayName(): string | null;

  identity(): string;
}

export class Auth implements IAuth {
  private _token = new Jwt('');

  constructor(public clientId: string) {}

  async authenticateUsernamePassword(
    username: string,
    password: string,
    rememberMe = false
  ): Promise<{
    state: AuthState;
    type?: 'email' | 'authenticator';
    challenge?: string;
  }> {
    const body = new URLSearchParams({
      username: username.replace(/\s/g, '').toLowerCase(),
      password: password.trim(),
      grant_type: 'password',
      client_id: this.clientId,
      scope: 'openid',
      identity: this.identity()
    });
    const oauthResponse = await fetch('/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body
    });

    if (!oauthResponse.ok) {
      const { message } = await oauthResponse.json();
      if (message.code === 'EmailUnverified') {
        return { state: 'EmailUnverified' };
      }
      return { state: 'Unauthenticated' };
    }
    const response = await oauthResponse.json();
    if ('mfa_type' in response) {
      return {
        state: 'MfaChallenge',
        type: response.mfa_type,
        challenge: response.challenge
      };
    } else {
      this.processAuthResponse(response, rememberMe);
      return { state: 'Authenticated' };
    }
  }

  processAuthResponse(response: TokenExchangeResponse, rememberMe: boolean) {
    this.updateTokens(
      response.access_token,
      response.refresh_token,
      rememberMe
    );

    if (response.id_token) {
      const jwt = new Jwt(response.id_token);
      this.updateTenant(jwt.tenant());
      localStorage.setItem('userDisplayName', jwt.displayName());
      if (jwt.email()) {
        localStorage.setItem('email', jwt.email());
      } else {
        localStorage.removeItem('email');
      }
      localStorage.setItem('tenant', jwt.tenant());
    }
  }

  async refresh(): Promise<AuthState> {
    const rememberMe: boolean =
      !!localStorage.getItem('tenant') &&
      !!localStorage.getItem('refreshToken');

    const tenant = rememberMe
      ? localStorage.getItem('tenant')
      : sessionStorage.getItem('tenant');
    const refreshToken = rememberMe
      ? localStorage.getItem('refreshToken')
      : sessionStorage.getItem('refreshToken');

    if (!tenant || !refreshToken) {
      return 'Unauthenticated';
    }

    const body = new URLSearchParams({
      refresh_token: refreshToken,
      tenant: tenant,
      grant_type: 'refresh_token',
      client_id: this.clientId
    });
    const oauthResponse = await fetch('/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body
    });

    if (!oauthResponse.ok) {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tenant');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('tenant');
      return 'Unauthenticated';
    }

    const response = (await oauthResponse.json()) as TokenExchangeResponse;
    this.processAuthResponse(response, rememberMe);
    return 'Authenticated';
  }

  async verifyEmail(tenant: string, token: string): Promise<void> {
    await fetch('/oauth2/email/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ tenant, token })
    });
  }

  inRole(role: Role): boolean {
    const value = this.token();
    return value ? new Jwt(value).inRole(role) : false;
  }

  hasScope(scope: string): boolean {
    const value = this.token();
    return value ? new Jwt(value).hasScope(scope) : false;
  }

  idp(): IDP | null {
    const value = this.token();
    if (!value) {
      return null;
    }
    switch (new Jwt(value).idp().toLowerCase()) {
      case 'google':
        return 'google';
      default:
        return null;
    }
  }

  requiresSsoElevation(): boolean {
    const jwt = new Jwt(this.token() || '');
    return (
      jwt.idp() === 'GOOGLE' &&
      !jwt.hasScope('google:admin.directory.user.readonly')
    );
  }

  logout(): void {
    const persistentValues: { [key: string]: string | null } = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i) || '';
      if (
        key.startsWith('persist-') ||
        key.startsWith('debug-') ||
        key.startsWith('sso-') ||
        key === '.pcuuid'
      ) {
        persistentValues[key] = localStorage.getItem(key);
      }
    }
    localStorage.clear();
    sessionStorage.clear();
    for (const key in persistentValues) {
      localStorage.setItem(key, persistentValues[key] || '');
    }
  }

  email(): string | null {
    return localStorage.getItem('email');
  }

  token(): string | null {
    return sessionStorage.getItem('token') || null;
  }

  refreshToken(): string | null {
    return (
      sessionStorage.getItem('refreshToken') ||
      localStorage.getItem('refreshToken') ||
      null
    );
  }

  updateTokens(
    userToken?: string,
    refreshToken?: string,
    rememberMe?: boolean
  ) {
    if (userToken) {
      sessionStorage.setItem('token', userToken);
    } else {
      sessionStorage.removeItem('token');
    }

    if (refreshToken) {
      rememberMe
        ? localStorage.setItem('refreshToken', refreshToken)
        : sessionStorage.setItem('refreshToken', refreshToken);
    } else {
      rememberMe
        ? localStorage.removeItem('refreshToken')
        : sessionStorage.removeItem('refreshToken');
    }
  }

  updateTenant(tenant: string): void {
    if (tenant) {
      sessionStorage.setItem('tenant', tenant);
    } else {
      sessionStorage.removeItem('tenant');
    }
  }

  expiresAt(): number {
    const value = this.token();
    return value ? new Jwt(value).expiresAt() : Date.now() - 700000;
  }

  isAuthenticated(): boolean {
    const value = this.token();
    return value ? new Jwt(value).valid() : false;
  }

  modules(): Modules {
    const value = this.token();
    return value ? new Jwt(value).modules() : 0;
  }

  displayName(): string | null {
    return localStorage.getItem('userDisplayName') || null;
  }

  identity(): string {
    const uuid = localStorage.getItem('.pcuuid');
    if (uuid) {
      document.cookie = `.pcuuid=${uuid}; path=/`;
      return uuid;
    }
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('.pcuuid='))
      ?.split('=')[1];
    if (cookieValue) {
      localStorage.setItem('.pcuuid', cookieValue);
      return cookieValue;
    }
    const newUuid = quickid('', 'high');
    localStorage.setItem('.pcuuid', newUuid);
    document.cookie = `.pcuuid=${newUuid}; path=/`;
    return newUuid;
  }
}
