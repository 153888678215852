import { RuleBase, RuleType } from '../rule-base';
import { BlockStatus } from '../rule-models';
import { InternalTest } from '../core/internal-test';
import { InternalAssignment } from '../core/internal-assignment';

export class ApprovalPublicHoliday extends RuleBase {
  type(): RuleType {
    return RuleType.schedule;
  }

  canSchedule(
    _test: InternalTest,
    _plan: InternalAssignment[][],
    blocked: BlockStatus[]
  ): void {
    if (!this.specs.isEnabled('public-hols')) {
      return;
    }
    for (const [i, day] of this.days.entries()) {
      const match = this.publicHols.find(
        (x) =>
          x.getFullYear() === day.getFullYear() &&
          x.getMonth() === day.getMonth() &&
          x.getDate() === day.getDate()
      );
      // block if public holiday and not a sunday
      if (match && day.getDay() !== 0) {
        blocked[i].blocked = true;
        blocked[i].notes.push(
          `Arbeiten an einem Feiertag ist nur mit Freigabe der Leitung erlaubt`
        );
      }
    }
  }
}
