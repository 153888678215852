import { useEffect, useRef } from 'react';

export interface TableMenuProps {
  options: { label: string; disabled?: boolean; callback?: () => void }[];
}

export const TableMenu = (props: TableMenuProps) => {
  const ref = useRef<HTMLDetailsElement>(null);
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const contained = ref.current?.contains(e.target as Node);
      const details = (e.target as HTMLDetailsElement).closest('details');
      if (details && details.open && contained) {
        details?.removeAttribute('open');
        e.stopPropagation();
        e.preventDefault();
      }
      if (!contained && !details) {
        ref.current?.removeAttribute('open');
      }
    };
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        ref.current?.removeAttribute('open');
      }
    };
    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const handleOptionClick = (target: EventTarget, callback?: () => void) => {
    const details = (target as HTMLElement).closest('details');
    details?.removeAttribute('open');
    callback?.();
  };

  return (
    <details ref={ref} className="relative inline-block">
      <summary className="border-silver-400 outline-focus hover:bg-silver-200 flex h-8 w-8 cursor-pointer list-none items-center justify-center rounded border bg-neutral-200">
        <span className="material-icons">more_vert</span>
      </summary>
      <ul className="shadow-hdr absolute right-0 z-10 w-40 bg-white py-1 text-left">
        {props.options.map((option, index) => (
          <li
            key={index}
            tabIndex={0}
            className="outline-focus cursor-pointer py-1 pl-4 text-sm hover:bg-black/10 focus:bg-black/10"
            style={{
              pointerEvents: option.disabled ? 'none' : 'auto',
              opacity: option.disabled ? 0.5 : 1
            }}
            onClick={(e) => handleOptionClick(e.target, option.callback)}
            onKeyDown={(e) =>
              e.key === 'Enter' && handleOptionClick(e.target, option.callback)
            }
          >
            {option.label}
          </li>
        ))}
      </ul>
    </details>
  );
};
