import { Checkbox, LinkButton } from '@kidsmanager/ui-core';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface FeatureCardProps {
  children: ReactNode;
  settingsLink?: string;
  titleKey: string;
  descriptionKey: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
}

export const FeatureCard = (props: FeatureCardProps) => {
  const { t } = useTranslation('admin');
  const navigate = useNavigate();
  return (
    <div className="flex max-w-xl gap-4 rounded-md bg-white px-3 pb-2">
      <div className="flex items-center">{props.children}</div>
      <div className="min-h-24 flex-1 py-4">
        <h4 className="mb-1 text-lg font-medium">{t(props.titleKey)}</h4>
        <p className="text-sm">{t(props.descriptionKey)}</p>
      </div>
      <div className="relative flex min-w-24 items-center justify-end">
        <Checkbox checked={props.checked} onChange={props.onChange}></Checkbox>
        {props.settingsLink && (
          <div className="absolute bottom-0 right-0">
            <LinkButton onClick={() => navigate(props.settingsLink || '')}>
              {t('common.settings')}
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  );
};
