import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Button,
  DialogConfirm,
  DialogConfirmUnsaved,
  DialogContext,
  GhostButton,
  LinkButton,
  Tabs
} from '@kidsmanager/ui-core';
import { IRosterTemplate } from '@kidsmanager/util-models';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ContextAdminRosterOutlet } from './context-admin-roster-outlet';
import { MasterDetail } from '../components/master-detail';
import { useTranslation } from 'react-i18next';

export const AdminRosters = () => {
  const params = useParams<{ id: string }>();
  const client = useContext(ClientBackendContext);
  const dialog = useContext(DialogContext);
  const navigate = useNavigate();
  const [hasChanges, setHasChanges] = useState({ template: false });
  const [templates, setTemplates] = useState<IRosterTemplate[]>([]);
  const [selected, setSelected] = useState<IRosterTemplate>();
  const [area, setArea] = useState('');
  const { t } = useTranslation('admin-roster');

  const tabs = useMemo(() => {
    return [
      { path: '', title: t('roster.tab_general') },
      { path: 'rules', title: t('roster.tab_rules') },
      { path: 'non-work', title: t('roster.tab_non_work') }
    ];
  }, [t]);

  useEffect(() => {
    client.admin.roster.templates.find().then((templates) => {
      templates.sort((a, b) => a.name.localeCompare(b.name));
      setTemplates(templates);
      const editable =
        templates.find((t) => t.id === params.id) || templates[0];
      setSelected(structuredClone(editable));
    });
  }, [client, params.id]);

  const handleTabChange = (tab: string) => {
    setArea(tab);
  };

  const handleAddTemplate = () => {
    client.admin.roster.templates.add().then((template) => {
      if (!template) {
        return;
      }
      setTemplates([...templates, template]);
      setSelected(structuredClone(template));
    });
  };

  const handleChange = () => {
    if (!selected) {
      return;
    }
    const clean = templates.find((t) => t.id === selected.id);
    setHasChanges({
      template: JSON.stringify(clean) !== JSON.stringify(selected)
    });
  };

  const handleSave = () => {
    if (!selected) {
      return;
    }
    client.admin.roster.templates.update(selected).then((updated) => {
      //replace the updated template in the list
      const index = templates.findIndex((t) => t.id === updated.id);
      const updatedTemplates = [...templates];
      updatedTemplates[index] = updated;
      setTemplates(updatedTemplates);
      setSelected(structuredClone(updated));
      setHasChanges({ template: false });
    });
  };

  const handleCancel = () => {
    if (!selected) {
      return;
    }
    const id = selected.id;
    const current = templates.find((t) => t.id === id);
    if (!current) {
      return;
    }
    setSelected(structuredClone(current));
    setHasChanges({ template: false });
  };

  const handleDelete = (id: string) => {
    dialog.open(
      <DialogConfirm
        callToAction={t('common.delete')}
        onCancel={() => dialog.close()}
        onConfirm={() => {
          client.admin.roster.templates.delete(id).then(() => {
            const updatedTemplates = templates.filter((t) => t.id !== id);
            if (updatedTemplates.length > 0) {
              setSelected(structuredClone(updatedTemplates[0]));
            } else {
              setSelected(undefined);
            }
            setTemplates(updatedTemplates);
            dialog.close();
          });
        }}
      >
        <div>{t('roster.delete_confirm', { name: selected?.name })}</div>
      </DialogConfirm>
    );
  };

  const handleTemplateSwitch = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: string,
    area: string
  ) => {
    if (hasChanges.template) {
      e.preventDefault();
      dialog.open(
        <DialogConfirmUnsaved
          onCancel={() => {
            dialog.close();
          }}
          onConfirm={() => {
            dialog.close();
            setHasChanges({ template: false });
            navigate(`/admin/roster/${id}/${area}`);
          }}
        ></DialogConfirmUnsaved>
      );
    }
  };

  return (
    <MasterDetail
      widths={{ master: 300 }}
      master={
        <div className="px-4 py-6">
          <h1 className="mb-2">{t('roster.shift_template')}</h1>
          <ul className="border-silver-200 min-h-64 border py-2">
            {templates.map((template) => (
              <li
                key={template.id}
                style={{
                  backgroundColor:
                    selected?.id === template.id
                      ? 'var(--color-secondary)'
                      : '',
                  color: selected?.id === template.id ? 'white' : ''
                }}
              >
                <Link
                  className="relative block w-full px-3 leading-10"
                  to={`/admin/roster/${template.id}/${area}`}
                  onClick={(e) => handleTemplateSwitch(e, template.id, area)}
                >
                  {template.name}
                  {selected?.id === template.id && (
                    <button
                      onClick={handleDelete.bind(this, selected.id)}
                      className="material-icons absolute right-1 top-1/2 flex h-8 w-8 -translate-y-1/2 items-center rounded-full hover:bg-white/20"
                    >
                      close
                    </button>
                  )}
                </Link>
              </li>
            ))}
          </ul>
          <div className="my-1 text-right">
            <LinkButton onClick={handleAddTemplate.bind(this)}>
              + {t('common.add')}
            </LinkButton>
          </div>
        </div>
      }
      detail={
        <div className="relative">
          <div className="flex justify-end gap-2">
            <GhostButton
              type="reset"
              disabled={!hasChanges.template}
              onClick={handleCancel.bind(this)}
            >
              {t('common.cancel')}
            </GhostButton>
            <Button
              color="accent"
              disabled={!hasChanges.template}
              onClick={handleSave.bind(this)}
            >
              {t('common.save')}
            </Button>
          </div>
          <Tabs
            basePath={`/admin/roster/${selected?.id}`}
            tabs={tabs}
            onChange={handleTabChange.bind(this)}
          >
            <Outlet
              context={
                {
                  template: selected,
                  onChange: handleChange.bind(this)
                } as ContextAdminRosterOutlet
              }
            />
          </Tabs>
        </div>
      }
    />
  );
};
